import React, { useEffect, useState } from "react";
import axios from 'axios';
import PostCard from '../../../components/PostCard/PostCard';
import '../turmas.css';
import dotenv from 'dotenv';

dotenv.config(); // Adicionando esta linha

function SegundoAno() {
  const [posts, setPosts] = useState([]);

  const API = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
  });

  useEffect(() => {
    const getPosts = async () => {
      try {
        const { data: allPosts } = await API.get('/posts', {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        });
        const classPosts = allPosts.filter(post => post.classId === 'quinto-ano');
        const sortedPosts = classPosts.sort((a, b) => new Date(b.date) - new Date(a.date));
        setPosts(sortedPosts);
      } catch (error) {
        console.error("Erro ao buscar posts:", error);
      }
    }
    getPosts();
  }, []);

  return (
    <div>
      <h1 className="title">Segundo-Ano</h1>
      <div className="post-content">
        {
          posts.map((post, index) => (<PostCard key={index} post={post} />))
        }
      </div>
    </div>
  );
}

export default SegundoAno;

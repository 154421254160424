import axios from "axios";
import dotenv from 'dotenv';

dotenv.config();


export const API = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export const createSession = async (username, password) => {
  const request =  await API.post("/auth/login", { username, password })
  // console.log(request);
  return request;
};

export const createUser = async (name, email, username, usertype, password, confirmpassword) => {
  const request = await API.post("/register", { name, email, username, usertype, password, confirmpassword });
  // console.log(request);
  return request;
};

export const getUsers = async (token) => {
  const request = await API.get("/users", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  // console.log(request.data);
  localStorage.setItem("users", JSON.stringify(request.data));
  return request.data;
};
// getUsers().then((users) => console.log(users));

export const getUser = async (token, id) => {
  const request = await API.get(`/users/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  // console.log(request.data);
  return request.data;
};
getUser().then((user) => console.log(user)); 

// export const updateUser = async (token, id, name, email, password) => {
//   const request = await API.put(`/users/${id}`, { name, email, password }, {
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   });
//   console.log(request.data);
//   return request.data;
// }
// updateUser().then((user) => console.log(user));

export const getTurmas = async (token) => {
  const request = await API.get("/classes", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  console.log(request.data);
  localStorage.setItem("turmas", JSON.stringify(request.data));
  return request.data;
}
getTurmas().then((turmas) => console.log(turmas));

export const getPosts = async (token) => {
   const request = await API.get("/posts", {
     headers: {
       Authorization: `Bearer ${token}`,
     },
   });
   console.log(request.data);
   localStorage.setItem("posts", JSON.stringify(request.data));
   return request.data;
 }
 getPosts().then((posts) => console.log(posts));

export const updateUserAccess = async (token) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const userId = user._id;
  const request = await API.put(`/users/${userId}/access`, {}, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  console.log(request.data);
  return request.data;
};

export const getMessages = async (token) => {
  const request = await API.get("/messages", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  console.log(request.data);
  localStorage.setItem("messages", JSON.stringify(request.data));
  console.log(request.data);
  return request.data;
};

export const updateMessage = async (messageId, read) => {
  try {
    const response = await fetch(`/messages/${messageId}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ read })
    });
    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    
  }
}

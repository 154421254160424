import React, { useState, useEffect, useRef } from 'react';
import AvatarEditor from 'react-avatar-editor';
import styled from 'styled-components';
import axios from 'axios';

const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  background-image: linear-gradient(to bottom right, #5b4288, #DE012D);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const ProfileImage = styled.img`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 20px;
  border: 5px solid #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    width: 150px;
    height: 150px;
  }
`;

const ProfileName = styled.h1`
  font-size: 48px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 10px;
  color: #fff;

  @media (max-width: 768px) {
    font-size: 36px;
  }
`;

const ProfileBio = styled.p`
  font-size: 24px;
  text-align: center;
  margin-bottom: 30px;
  color: #fff;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const ProfileDetails = styled.ul`
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  color: #212529;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ProfileDetail = styled.li`
  font-size: 36px;
  font-weight: 700;
  margin-right: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  padding: 20px;

  @media (max-width: 768px) {
    margin-right: 30;
    margin-bottom: 20px;
    font-size: 18px;
  }
`;

const Profile = () => {
  const [user, setUser] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const editorRef = useRef(null);

  const baseUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchUser = async () => {
      const storedUser = localStorage.getItem('user');
      if (storedUser) {
        const parsedUser = JSON.parse(storedUser);

        // Verifica se a imagem está disponível localmente
        if (!parsedUser.photo || parsedUser.photo === 'default-image-url') {
          // Faz uma requisição para buscar a imagem do servidor
          try {
            const response = await axios.get(`${baseUrl}/uploads/images/profile/${parsedUser.photo}`, {
              headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
              },
              responseType: 'blob'
            });

            if (response.status === 200) {
              const photoUrl = URL.createObjectURL(response.data);
              parsedUser.photo = photoUrl;
              localStorage.setItem('user', JSON.stringify(parsedUser));
            }
          } catch (error) {
            console.error('Erro ao buscar a imagem do servidor:', error);
          }
        }

        setUser(parsedUser);
      }
    };

    fetchUser();
  }, [baseUrl]);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };

  const handleSave = async () => {
    if (selectedImage) {
      const formData = new FormData();
      formData.append('file', selectedImage);

      try {
        const response = await axios.post(`${baseUrl}/uploads/profile`, formData, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data'
          }
        });

        if (response.status === 201) {
          console.log('Foto de perfil atualizada com sucesso');
          const canvas = editorRef.current.getImageScaledToCanvas().toDataURL();
          const updatedUser = { ...user, photo: canvas };
          setUser(updatedUser);
          localStorage.setItem('user', JSON.stringify(updatedUser));
        }
      } catch (error) {
        console.error('Erro ao salvar a imagem:', error.response ? error.response.data : error.message);
      }
    }
  };

  return (
    <ProfileWrapper>
      <ProfileImage src={user.photo || 'default-image-url'} alt="Profile" />
      <ProfileName>{user.name}</ProfileName>
      <ProfileBio>{user.bio}</ProfileBio>
      <ProfileDetails>
        <ProfileDetail>Email: {user.email}</ProfileDetail>
        <ProfileDetail>Função: {user.usertype}</ProfileDetail>
        <ProfileDetail>ID de Usuário: {user.id}</ProfileDetail>
      </ProfileDetails>
      <input type="file" accept="image/*" onChange={handleImageUpload} />
      {selectedImage && (
        <AvatarEditor
          ref={editorRef}
          image={selectedImage}
          width={250}
          height={250}
          border={50}
          borderRadius={125}
          scale={1.2}
          rotate={0}
        />
      )}
      <button onClick={handleSave}>Save</button>
    </ProfileWrapper>
  );
};

export default Profile;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Banners.css'; // Importe o arquivo CSS

const Banners = ({ setCurrentBanner }) => {
  const [banners, setBanners] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);

  const baseUrl = process.env.REACT_APP_BASE_URL;

  const fetchBanners = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${baseUrl}/uploads/images/banner`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Cache-Control': 'no-cache', // Desabilita o cache
          'Pragma': 'no-cache',
          'Expires': '0'
        }
      });
      if (Array.isArray(response.data)) {
        console.log('Banners recebidos:', response.data.length); // Isso irá mostrar quantos banners estão sendo recebidos
        setBanners(response.data); // Atualiza o estado com os banners recebidos do servidor
      } else {
        console.error('Resposta da API não é uma array:', response.data);
        setBanners([]);
      }
    } catch (error) {
      console.error('Erro ao buscar os banners:', error);
      setBanners([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBanners(); // Chama a função para buscar os banners ao montar o componente
  }, [baseUrl]); // Adiciona baseUrl como dependência para garantir que useEffect seja executado novamente se a URL mudar

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };

  const adicionarBanner = async () => {
    if (selectedImage) {
      try {
        const formData = new FormData();
        formData.append('file', selectedImage);

        await axios.post(`${baseUrl}/uploads/banner`, formData, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data',
            'Cache-Control': 'no-cache', // Desabilita o cache
            'Pragma': 'no-cache',
            'Expires': '0'
          }
        });

        // Após o upload, buscar os banners novamente para atualizar a lista com a nova imagem
        fetchBanners();

        setSelectedImage(null); // Limpa o estado da imagem selecionada após o upload
      } catch (error) {
        console.error('Erro ao enviar a imagem:', error);
      }
    }
  };

  const handleBannerClick = (url) => {
    setCurrentBanner(url); // Define o banner atual ao clicar em um banner da lista
  };

  return (
    <div className="container">
      <h1>Banners</h1>
      <input type="file" accept="image/*" onChange={handleImageUpload} className="file-input" />
      <button onClick={adicionarBanner} className="upload-button">Adicionar Banner</button>
      <ul className="banner-list">
        {loading ? (
          <div>Carregando...</div>
        ) : banners.length > 0 ? (
          banners.map((banner) => (
            <li key={banner.filename} className="banner-item" onClick={() => handleBannerClick(banner.url)}>
              <img src={banner.url} alt={`Banner ${banner.filename}`} className="banner-image" />
            </li>
          ))
        ) : (
          <div>Nenhum banner disponível</div>
        )}
      </ul>
    </div>
  );
};

export default Banners;

import React from 'react';
import { Container, Typography, Paper, Grid, Box } from '@mui/material';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';

const ProgramsPage = () => {
  return (
    <Container maxWidth="md" sx={{ py: 4, mb:40}}>
      <Typography variant="h4" gutterBottom>
        <EventAvailableIcon sx={{ mr: 1 }} />
        Programas
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Programa de Atividades Extracurriculares
            </Typography>
            <Typography variant="body1" paragraph>
              Oferecemos uma ampla gama de atividades extracurriculares que visam o desenvolvimento integral dos alunos. Desde clubes de robótica e programação até aulas de música e esportes, cada aluno pode encontrar uma atividade que atenda seus interesses e habilidades.
            </Typography>
            <Typography variant="body1">
              <strong>Exemplos de Atividades:</strong>
              <ul>
                <li>Robótica e Tecnologia</li>
                <li>Teatro e Artes</li>
                <li>Esportes Coletivos e Individuais</li>
                <li>Clubes de Leitura e Debate</li>
              </ul>
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Programa de Incentivo Acadêmico
            </Typography>
            <Typography variant="body1" paragraph>
              Nosso programa de incentivo acadêmico busca motivar os alunos a alcançar seu potencial máximo. Oferecemos mentorias, workshops e concursos acadêmicos para reforçar o aprendizado e reconhecer os melhores desempenhos.
            </Typography>
            <Typography variant="body1">
              <strong>Características do Programa:</strong>
              <ul>
                <li>Mentoria com Professores Especializados</li>
                <li>Workshops de Desenvolvimento de Habilidades</li>
                <li>Concursos e Olimpíadas Acadêmicas</li>
                <li>Reconhecimento e Premiação dos Melhores Alunos</li>
              </ul>
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Projetos Especiais
            </Typography>
            <Typography variant="body1" paragraph>
              Participamos de diversos projetos especiais que visam engajar os alunos em atividades práticas e sociais, promovendo o aprendizado fora da sala de aula. Estes projetos são uma excelente oportunidade para os alunos aplicarem conhecimentos em situações reais.
            </Typography>
            <Typography variant="body1">
              <strong>Exemplos de Projetos:</strong>
              <ul>
                <li>Projetos de Sustentabilidade e Meio Ambiente</li>
                <li>Feiras de Ciências e Tecnologia</li>
                <li>Programas de Intercâmbio Cultural</li>
                <li>Parcerias com Organizações Comunitárias</li>
              </ul>
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Programas de Desenvolvimento Pessoal
            </Typography>
            <Typography variant="body1" paragraph>
              Nossos programas de desenvolvimento pessoal são projetados para ajudar os alunos a desenvolver habilidades de liderança, trabalho em equipe e autoconfiança. Incluem atividades de orientação, treinamentos e sessões de coaching.
            </Typography>
            <Typography variant="body1">
              <strong>Aspectos do Programa:</strong>
              <ul>
                <li>Treinamentos de Liderança e Comunicação</li>
                <li>Atividades de Team Building</li>
                <li>Workshops de Planejamento de Carreira</li>
                <li>Coaching Individual e em Grupo</li>
              </ul>
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ProgramsPage;

import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../Contexts/auth";
import Campaigns from "../Campaigns/Campaigns";
import { Box, Button, Typography, Fab } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const HomePage = () => {
  const { authenticated, logout } = useContext(AuthContext);
  const [user, setUser] = useState({});
  const [turmas, setTurmas] = useState([]);

  const handleLogout = () => {
    logout();
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      setUser(user);
      // console.log(user);
    }
  }, []);

  useEffect(() => {
    const turmas = JSON.parse(localStorage.getItem('turmas'));
    if (turmas) {
      setTurmas(turmas);
      console.log(turmas);
    }
  }, []);

  const handleWhatsAppClick = () => {
    window.open('https://wa.me/+5585987660739', '_blank');
  };

  return (
    <Box id="home" sx={{ width: '100%', minHeight: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box className="parent-welcome" sx={{ marginBottom: 2 }}>
        {/* <Typography variant="h3">Home</Typography> */}
      </Box>
      <Box className="carousel-item" sx={{ width: '100%' }}>
        {/* Componente Carousel aqui */}
      </Box>
      <Box sx={{ flexGrow: 1, width: '100%' }}>
        <Campaigns />
      </Box>
      <Box sx={{ textAlign: 'center', marginBottom: 25 }}>
        {/* <Button variant="contained" color="primary" onClick={handleLogout}>
          Sair
        </Button> */}
      </Box>
      {/* <Typography>{String(authenticated)}</Typography> */}

      {/* Botão Flutuante do WhatsApp */}
      <Fab
        color="primary"
        aria-label="whatsapp"
        onClick={handleWhatsAppClick}
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
          backgroundColor: '#25D366',
          '&:hover': {
            backgroundColor: '#128C7E'
          }
        }}
      >
        <WhatsAppIcon />
      </Fab>
    </Box>
  );
};

export default HomePage;

import {
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';

import React, { useContext } from "react";

import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';

import { AuthProvider, AuthContext } from './Contexts/auth';
import CreateTurmas from './pages/CreateTurmas/CreateTurmas';
import CreatePosts from './pages/CreatePosts/CreatePosts';
import Infantil1 from './pages/classes/infantil-I/infantil-I';
import Infantil2 from './pages/classes/infantil-II/infantil-II';
import Infantil3 from './pages/classes/infantil-III/infantil-III';
import Infantil4 from './pages/classes/infantil-IV/infantil-IV';
import Infantil5 from './pages/classes/infantil-V/infantil-V';
import PrimeiroAno from './pages/classes/primeiro-ano/primeiro-ano';
import SegundoAno from './pages/classes/segundo-ano/segundo-ano';
import TerceiroAno from './pages/classes/terceiro-ano/terceiro-ano';
import QuartoAno from './pages/classes/quarto-ano/quarto-ano';
import QuintoAno from './pages/classes/quinto-ano/quinto-ano';
import NewPost from './pages/newposts/newpost';
import EditPost from './pages/editposts/editpost';
import DeletePost from './pages/deleteposts/deletepost';
import UserProfile from './pages/UserProfile/UserProfile';
import AdminPanel from './pages/admin_panel/AdminPanel';
import Users from './pages/Users/Users';
import EditUser from './pages/EditUser/EditUser';
import DeleteUser from './pages/DeleteUser/DeleteUser';
import SignUp from './pages/register/SignUp';
import Messages from './pages/Messages/Messages';
import SentNotification from './pages/Messages/SentNotification';
import Graphics from './pages/metricas/Graphs';
import Financeiro from './pages/Financeiro/Financeiro';
import Alunos from './pages/Alunos/Alunos';
import Professores from './pages/Professores/Professores';
import Banners from './pages/Banners/Banners';
import NewUser from './pages/newUser/NewUser';
import Atividades from './pages/activities/Activities'
import Campaigns from './pages/Campaigns/Campaigns';
import Admission from './pages/Admission/Admission';
import OurSchool from './pages/OurSchool/OurSchool';
import ContactPage from './pages/ContactPage/ContactPage';
import ContentPage from './pages/ContentPage/ContentPage';
import ResponsiblesAreaPage from './pages/ResponsibleAreaPage/ResponsibleAreaPage';
import EducationLevelsPage from './pages/EducationLevel/EducationLevel';
import ProgramsPage from './pages/ProgramsPage/ProgramsPage';
import Matriculas2025 from './pages/Matriculas/Matriculas2025';

const AppRoutes = () => {

const Private = ({children}) => {
  const { authenticated, loading } = useContext(AuthContext);

  if (loading) {
    return <div className='loading'>Carregando...</div>;
  }

  if (!authenticated) {
    return <Navigate to="/login" />
  }

  return children;
};

  return (
        <Routes>
          <Route exact path="/login" element={<LoginPage />} />
          <Route path="/" element={<HomePage />} />
          <Route path="/:id" element={<Private><CreateTurmas /></Private>} />
          <Route path="/posts" element={<Private><CreatePosts /></Private>} />
          <Route path="/posts/classe/Infantil-I" element={<Private><Infantil1 /></Private>} />
          <Route path="/posts/classe/Infantil-II" element={<Private><Infantil2 /></Private>} />
          <Route path="/posts/classe/Infantil-III" element={<Private><Infantil3 /></Private>} />
          <Route path="/posts/classe/Infantil-IV" element={<Private><Infantil4 /></Private>} />
          <Route path="/posts/classe/Infantil-V" element={<Private><Infantil5 /></Private>} />
          <Route path="/posts/classe/primeiro-ano" element={<Private><PrimeiroAno /></Private>} />
          <Route path="/posts/classe/segundo-ano" element={<Private><SegundoAno /></Private>} />
          <Route path="/posts/classe/terceiro-ano" element={<Private><TerceiroAno /></Private>} />
          <Route path="/posts/classe/quarto-ano" element={<Private><QuartoAno /></Private>} />
          <Route path="/posts/classe/quinto-ano" element={<Private><QuintoAno /></Private>} />
          <Route path="/posts/newpost" element={<Private><NewPost /></Private>} />
          <Route path="/posts/:id/editposts" element={<Private><EditPost /></Private>} />
          <Route path="/posts/:id/deleteposts" element={<Private><DeletePost /></Private>} />
          <Route path='/turmas/posts/classe/primeiro-ano' element={<Private><PrimeiroAno /></Private>} />
          <Route path='/turmas/posts/classe/segundo-ano' element={<Private><SegundoAno /></Private>} />
          <Route path='/turmas/posts/classe/terceiro-ano' element={<Private><TerceiroAno /></Private>}  />
          <Route path='/turmas/posts/classe/quarto-ano' element={<Private><QuartoAno /></Private>}  />
          <Route path='/turmas/posts/classe/quinto-ano' element={<Private><QuintoAno /></Private>}  />
          <Route path='/turmas/posts/classe/Infantil-I' element={<Private><Infantil1 /></Private>}  />
          <Route path='/turmas/posts/classe/Infantil-II' element={<Private><Infantil2 /></Private>}  />
          <Route path='/turmas/posts/classe/Infantil-III' element={<Private><Infantil3 /></Private>}  />
          <Route path='/turmas/posts/classe/Infantil-IV' element={<Private><Infantil4 /></Private>}  />
          <Route path='/turmas/posts/classe/Infantil-V' element={<Private><Infantil5 /></Private>}  />
          <Route path="/profile" element={<Private><UserProfile /></Private>} />
          <Route path="/admin" element={<Private><AdminPanel /></Private>} />
          <Route path="/users" element={<Private><Users /></Private>} />
          <Route path="/users/newuser" element={<Private><NewUser /></Private>} />
          <Route path="/users/:id/edituser" element={<Private><EditUser /></Private>} />
          <Route path="/users/:id/deleteuser" element={<Private><DeleteUser /></Private>} />
          <Route path="/register" element={<SignUp />} />
          <Route path="/timeline" element={<Private><Messages /></Private>} />
          <Route path="/timeline/sent" element={<Private><SentNotification /></Private>} />
          <Route path="/metrics" element={<Private><Graphics /></Private>} />
          <Route path="/payments" element={<Private><Financeiro /></Private>} />
          <Route path="*" element={<h1>Página não encontrada</h1>} />
          <Route path="/alunos" element={<Private><Alunos /></Private>} />
          <Route path="/professores" element={<Private><Professores /></Private>} />
          <Route path="/mideas" element={<Private><Banners /></Private>} />
          <Route path="/atividades" element={<Private><Atividades /></Private>} />
          <Route path="/campaigns" element={<Private><Campaigns /></Private>} />
          <Route path="/admissao" element={<Admission />} />
          <Route path="/nossa-escola" element={<OurSchool />} />
          <Route path="/contato" element={<ContactPage />} />
          <Route path="/conteudos" element={<ContentPage />} />
          <Route path="/area-do-responsavel" element={<ResponsiblesAreaPage />} />
          <Route path="/niveis-de-ensino" element={<EducationLevelsPage />} />
          <Route path="/programas" element={<ProgramsPage />} />
          <Route path="/matriculas2025" element={<Matriculas2025 />} />
        </Routes>
  );
}

export default AppRoutes;
// Página de Edição de Post

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Footer from '../../components/footer/footer';
// import Select from 'react-select';
import * as dotenv from 'dotenv';

dotenv.config();

function EditUser() {
  const { id } = useParams();
  const [name, setName] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [photo, setPhoto] = useState('');
  const [usertype, setUsertype] = useState('');
  const [password, setPassword] = useState('');
  const [confirmpassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();

  const API = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
  });

  useEffect(() => {
    async function fetchUser() {
      const response = await API.get(`/users/${id}`);
      console.log(response);
      const { name, username, email, photo, usertype, password, confirmpassword } = response.data;
      setName(name);
      setUsername(username);
      setEmail(email);
      setPhoto(photo);
      setUsertype(usertype);
      setPassword(password);
      setConfirmPassword(confirmpassword);
    }
    fetchUser();
  }, [id]);

  async function handleSubmit(e) {
    e.preventDefault();
    const data = {
      name,
      username,
      email,
      photo,
      usertype,
      password,
      confirmpassword
    };
    // editar um usuário com autenticação
    const token = localStorage.getItem('token');
    const response = await API.put(`/user/${id}`, data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
    console.log(response);
    alert('Usuário editado com sucesso!');
    navigate(`/users`);
  }

  return (
    <>
      <h1 className='page-title'>Editar Usuário</h1>
      <div id="new-post">
        <form onSubmit={handleSubmit} className="form">
          <div className="input-block">
            <input
              name="name"
              id="name"
              placeholder='Nome'
              required
              type="text"
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </div>
          <div className="input-block">
            <input
              name="username"
              id="username"
              placeholder='Nome de Usuário'
              required
              type="text"
              value={username}
              onChange={e => setUsername(e.target.value)}
            />
          </div>
          <div className="input-block">
            <input
              name="email"
              className="email"
              placeholder='email'
              id="email"
              type="email"
              required
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </div>
          <div className="input-block">
            <textarea
              name="password"
              id="password"
              required
              type="text"
              placeholder='Senha'
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
          </div>
          <div className="input-block">
            <textarea
              name="confirm-password"
              id="confirm-password"
              required
              type="text"
              placeholder='Confirme a senha'
              value={confirmpassword}
              onChange={e => setConfirmPassword(e.target.value)}
            />
          </div>
          <button type="submit">Salvar</button>
        </form>
      </div>
      <Footer />
    </>
  );
}

export default EditUser;

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import * as dotenv from 'dotenv';

dotenv.config();

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 400px;
  margin: 0 auto;
  color: #333;
`;

const Label = styled.label`
  font-weight: bold;
`;

const Input = styled.input`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Button = styled.button`
  padding: 8px;
  background-color: #0077cc;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #005fa3;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

function SentNotification() {
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');
  const [creator, setCreator] = useState('');
  const [usertype, setUsertype] = useState('');
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const API = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
  });

  useEffect(() => {
    const user = localStorage.getItem('user');
    const userId = JSON.parse(user).id;
    const userType = JSON.parse(user).usertype;
    console.log(userId, userType);
    setCreator(userId);
    setUsertype(userType);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const data = {
        subject,
        content,
        creator,
      };
      const token = localStorage.getItem('token');
      const response = await API.post('/messages', data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      console.log(response);
      setSubject('');
      setContent('');
      setCreator('');
      setLoading(false);
      alert('Post criado com sucesso!');
      navigate('/timeline');
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <div>
      <h1>Informes</h1>
      {usertype === 'administrator' ? (
        <Form onSubmit={handleSubmit}>
          <Label htmlFor="subject">Assunto</Label>
          <Input
            type="text"
            id="subject"
            name="subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            required
          />
          <Label htmlFor="content">Informe</Label>
          <textarea
            id="content"
            name="content"
            rows="5"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            required
          />
          <Label htmlFor="creator">Autor</Label>
          <Input
            type="text"
            id="creator"
            name="creator"
            value={creator}
            onChange={(e) => setCreator(e.target.value)}
            required
          />
          <Button disabled={loading}>
            {loading ? 'Enviando...' : 'Enviar'}
          </Button>
        </Form>
      ) : (
        <p>Desculpe, você não tem permissão para acessar esta página.</p>
      )}
    </div>
  );
}

export default SentNotification;

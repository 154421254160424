import React from 'react';
import { Box, Typography, Container, Grid, Divider } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import RoomIcon from '@mui/icons-material/Room';
import logo from '../../images/LogoMSDupla.png';

function Footer() {
  return (
    <Box
      sx={{
        backgroundImage: 'linear-gradient(to bottom, #004ba0, #1976d2)', // Gradiente para o fundo
        color: '#fff',
        py: 6, // Aumenta o padding vertical
        width: '100%',
        mt: { xs: 8, md: 0 }, // Margem superior em dispositivos móveis
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <Box
          component="img"
          src={logo}
          alt="logotipo da escola"
          sx={{
            height: 220,
            width: 220,
            mt: { xs: 20, md: 0 },  // Margem superior maior para telas menores
            mb: { xs: -1, md: -5 }  // Margem inferior para o espaçamento vertical
          }}
        />

        <Grid container spacing={4} justifyContent="center" alignItems="center">
          {/* Primeira coluna */}
          <Grid item xs={12} md={4}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              SEDE ÁLVARO WEYNE
            </Typography>
            <Typography variant="body1">
              <RoomIcon sx={{ verticalAlign: 'middle', mr: 1, color: '#FFD700' }} />
              Rua São Bernardo, 181 - Álvaro Weyne
            </Typography>
            <Typography variant="body1">
              <PhoneIcon sx={{ verticalAlign: 'middle', mr: 1, color: '#FFD700' }} />
              (85) 3047.0447 | 3228.3418
            </Typography>
            <Typography variant="body1">
              <WhatsAppIcon sx={{ verticalAlign: 'middle', mr: 1, color: '#FFD700' }} />
              (85) 98766.0739
            </Typography>
          </Grid>

          {/* Linha vertical no meio */}
          <Grid item xs={12} md={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Divider
              orientation="vertical"
              sx={{ borderColor: '#FFD700', borderWidth: '2px', height: '150px' }}
            />
          </Grid>

          {/* Segunda coluna */}
          <Grid item xs={12} md={4}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              SEDE CARLITO PAMPLONA
            </Typography>
            <Typography variant="body1">
              <RoomIcon sx={{ verticalAlign: 'middle', mr: 1, color: '#FFD700' }} />
              Av. Francisco Sá, 4001/07 - Carlito Pamplona
            </Typography>
            <Typography variant="body1">
              <PhoneIcon sx={{ verticalAlign: 'middle', mr: 1, color: '#FFD700' }} />
              (85) 3236.7700
            </Typography>
            <Typography variant="body1">
              <WhatsAppIcon sx={{ verticalAlign: 'middle', mr: 1, color: '#FFD700' }} />
              (85) 98902.3108
            </Typography>
          </Grid>
        </Grid>

        <Box sx={{ mt: 4 }}>
          <Typography variant="body1" sx={{ mb: 1 }}>
            <EmailIcon sx={{ verticalAlign: 'middle', mr: 1, color: '#FFD700' }} />
            secretaria@manueldasilva.com.br
          </Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            www.manueldasilva.com.br
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <FacebookIcon sx={{ mx: 2, color: '#FFD700' }} />
            <InstagramIcon sx={{ mx: 2, color: '#FFD700' }} />
          </Box>
        </Box>

        <Box sx={{ mt: 4 }}>
          <Typography variant="body2">© 2024 - Todos os direitos reservados</Typography>
          <Typography variant="body2">
            Desenvolvido por: <a href="https://www.linkedin.com/in/sergioabarbosa/" style={{ color: '#fff' }}>Sérgio Barbosa</a>
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}

export default Footer;

import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '../../Contexts/auth';
import axios from 'axios';
import './deletepost.css'
import * as dotenv from 'dotenv';

dotenv.config();

function DeletePost() {
  const { user } = useContext(AuthContext);
  const { id } = useParams();
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [classe, setClasse] = useState('');
  const [author, setAuthor] = useState('');
  const [date, setDate] = useState('');
  const navigate = useNavigate();

  const API = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
  });

  useEffect(() => {
    async function fetchPost() {
      const response = await API.get(`/posts/${id}`);
      const { title, content, creator, createdAt } = response.data;
      setTitle(title);
      setContent(content);
      setAuthor(creator);
      setClasse(classe);
      setDate(createdAt);
    }
    fetchPost();
  }, [id]);

  async function handleSubmit(e) {
    e.preventDefault();

    // alert(`Tem certeza que deseja excluir este post de ID ${id}?`)

    // excluir um post com autenticação
    const token = localStorage.getItem('token');
    const response = await API.delete(`/posts/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
    console.log(response);
    alert(`Post com o ID ${id} excluído com sucesso!`);
    navigate(`/posts`);
  }

  function handleCancel(e) {
    e.preventDefault();
    navigate(`/posts`);
  }

  if (user.usertype === 'administrator' || !user.usertype) {
  return (
    <>
      <h1 className='page-title'>Excluir Post</h1>
      <div id="deleted-post">
        <h1 className="name-post">{title}</h1>
        <p className="description-post">Descrição: {content}</p>
        <p className="author">Autor: {author}</p>
        <p className="date">Data: {date.toLocaleString('pt-BR', { timezone: 'UTC' })}</p>
        <p className="id">ID: {id}</p>
        <div className="button-container">
        <button type="submit" onClick={handleSubmit}>Confirmar</button>
        <button type="submit" onClick={handleCancel}>Cancelar</button>
        </div>
      </div>
    </>
  );
  } else {
    return (
      <div>
        <h1>Você não tem permissão para acessar essa página.</h1>
      </div>
    )
  }
}

export default DeletePost;

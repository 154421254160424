import React, { useEffect, useState } from "react";
import axios from "axios";
import Example from "./Example";
import Bar from "./Bar";
import Pizza from "./Pizza";
import AccessTimeChart from "./AccessTimeChart";
import styled from "styled-components";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import dotenv from 'dotenv';

dotenv.config();

const GraphicsCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  margin-top: 2rem;
  padding: 0 2rem 2rem 2rem;
`;

const GraphicsCardItem = styled.div`
  width: 45vw;
  margin-bottom: 2rem;
  border: 1px solid #ccc;
  align-items: center;
  text-align: center;
  justify-content: center;
  border-radius: 0.5rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  padding: 1rem 1.5rem 1.5rem 1.5rem;

  &:hover {
    transform: translateY(-0.25rem);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.3);
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const SkeletonItem = styled(Skeleton)`
  width: 45vw;
  height: 300px;
  margin-bottom: 2rem;
  border-radius: 0.5rem;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export default function GraphicsCard() {
  const [accessCounts, setAccessCounts] = useState([]);
  const [loading, setLoading] = useState(true);

  const baseUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchAccessCounts = async () => {
      try {
        const response = await axios.get(`${baseUrl}/user-access`);
        setAccessCounts(response.data); // Atualiza o estado com os acessos recebidos do servidor
      } catch (error) {
        console.error('Erro ao buscar os acessos:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAccessCounts();
  }, [baseUrl]);

  // Converte os dados para o formato esperado pelos gráficos
  const exampleData = {
    labels: accessCounts.map((access) => `${access._id.year}-${access._id.month}-${access._id.day} ${access._id.hour}:00`),
    datasets: [
      {
        label: "Access Counts",
        data: accessCounts.map((access) => access.count),
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(75,192,192,1)",
        borderWidth: 1,
      },
    ],
  };

  const accessTimeData = accessCounts.map((access) => ({
    _id: access._id, // Inclui o objeto completo
    count: access.count,
  }));

  const graphicsList = [
    <Example data={exampleData} key="example" />,
    <Bar data={exampleData} key="bar" />,
    <Pizza data={exampleData} key="pizza" />,
    <AccessTimeChart data={accessTimeData} key="accessTimeChart" />,
  ];

  return (
    <GraphicsCardContainer>
      {loading ? (
        <>
          <SkeletonItem />
          <SkeletonItem />
          <SkeletonItem />
          <SkeletonItem />
        </>
      ) : (
        graphicsList.map((item, index) => (
          <GraphicsCardItem key={index}>{item}</GraphicsCardItem>
        ))
      )}
    </GraphicsCardContainer>
  );
}

import React from 'react';
import { func, bool } from 'prop-types';
import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons';

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const SwitchLabel = styled.span`
  margin-left: 10px;
  color: ${({ theme }) => theme.text};
  font-size: 1rem;
`;

const SwitchButton = styled.div`
  width: 50px;
  height: 24px;
  background: ${({ isOn }) => (isOn ? '#2196F3' : '#ccc')};
  border-radius: 12px;
  position: relative;
  transition: background 0.3s;
`;

const SwitchCircle = styled.div`
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: ${({ isOn }) => (isOn ? 'calc(100% - 22px)' : '2px')};
  transition: left 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
`;

const Toggle = ({ isOn, toggleTheme }) => {
  return (
    <SwitchContainer onClick={toggleTheme}>
      <SwitchButton isOn={isOn}>
        <SwitchCircle isOn={isOn} />
      </SwitchButton>
      <SwitchLabel>
        <FontAwesomeIcon icon={isOn ? faMoon : faSun} style={{ marginRight: '5px' }} />
        {isOn ? "Darkmode ON" : "Darkmode OFF"}
      </SwitchLabel>
    </SwitchContainer>
  );
};

Toggle.propTypes = {
  toggleTheme: func.isRequired,
  isOn: bool.isRequired
};

export default Toggle;

import React from 'react';
import { Container, Typography, Paper, Grid } from '@mui/material';
import BookIcon from '@mui/icons-material/Book';

const ContentPage = () => {
  return (
    <Container maxWidth="md" sx={{ py: 4, mb:60 }}>
      <Typography variant="h4" gutterBottom>
        <BookIcon sx={{ mr: 1 }} />
        Conteúdos
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Matemática
            </Typography>
            <Typography variant="body1">
              Nosso conteúdo de Matemática abrange desde a aritmética básica até álgebra avançada, preparando os alunos para resolver problemas e aplicar conceitos matemáticos em diversas situações.
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Língua Portuguesa
            </Typography>
            <Typography variant="body1">
              Trabalhamos com leitura, produção textual, gramática e interpretação, desenvolvendo a habilidade dos alunos de se expressar corretamente e compreender textos de diferentes gêneros.
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Ciências
            </Typography>
            <Typography variant="body1">
              Aulas de Ciências proporcionam uma compreensão profunda dos fenômenos naturais, desde a biologia e química até a física e a geologia, estimulando a curiosidade e o interesse pela ciência.
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ContentPage;

import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CottageIcon from '@mui/icons-material/Cottage';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import ListIcon from '@mui/icons-material/List';
import MoreIcon from '@mui/icons-material/MoreVert';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import YoutubeIcon from '@mui/icons-material/YouTube';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { API } from "../../Services/api";
import MessageIcon from '@mui/icons-material/Message';
import Tooltip from '@mui/material/Tooltip';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import Logo from '../../images/LogosParteSuperior.png'; // Ajuste o caminho para o local do seu logo
import './PrimarySearchAppBar.css'; // Importando o CSS personalizado
import EmailIcon from '@mui/icons-material/Email';
import ComputexLogo from '../../images/computex_logo.jpg'; // Imagem do logo Computex

const theme = createTheme({
  palette: {
    primary: {
      main: '#01bdde', // Cor primária personalizada
    },
    secondary: {
      main: '#ffffff',
    },
    text: {
      primary: '#ffffff', // Cor de texto primária
      secondary: '#000000', // Cor de texto secundária (outra cor para testar contraste)
    },
  },
  components: {
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: '#ffffff', // Ajuste de cor dos ícones
          backgroundColor: 'transparent !important',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: '#000000', // Ajuste de cor do texto do menu
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#ffffff', // Ajuste de cor do texto da barra de ferramentas
        },
      },
    },
  },
});

export default function PrimarySearchAppBar() {
  const [user, setUser] = useState(null);
  const [turmas, setTurmas] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [usertype, setUsertype] = useState('');

  const navigate = useNavigate();

  const handleLoggout = () => {
    console.log('Logout initiated');
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    API.defaults.headers.Authorization = null;
    setUser(null);
    navigate('/login');
    setAnchorEl(null);
    handleMenuClose();
    console.log('User:', localStorage.getItem('user'));
    console.log('Token:', localStorage.getItem('token'));
  };

  const handleMainMenuClose = () => setMenuAnchorEl(null);
  const handleMainMenuOpen = (event) => setMenuAnchorEl(event.currentTarget);

  const navigateToComputex = () => {
    window.open('http://escola.computex.com.br:84/escola660/index2.php', '_blank'); // Abre a página da Computex em uma nova aba
  };

  const handleEmailClick = () => {
    window.open('http://webmail.manueldasilva.com.br/', '_blank'); // Abre o cliente de email padrão com o email preenchido
  };

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    if (storedUser) {
      setUser(storedUser);
      setUsertype(storedUser.usertype);
      // console.log('Usertype:', storedUser.usertype);
      // console.log('User:', storedUser);
    }
  }, []);

  useEffect(() => {
    const storedTurmas = JSON.parse(localStorage.getItem('turmas'));
    if (storedTurmas) {
      setTurmas(storedTurmas);
    }
  }, []);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const isMainMenuOpen = Boolean(menuAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileSandwichMenuOpen = (event) => {
    console.log('handleMobileSandwichMenuOpen');
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMobileMenuOpen = (event) => {
    console.log('handleMobileMenuOpen');
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const naviggateToProfile = () => {
    console.log('naviggateToProfile');
    navigate('/profile');
    handleMenuClose();
  };

  const naviggateToHome = () => {
    console.log('naviggateToHome');
    navigate('/');
    setAnchorEl(null);
    handleMenuClose();
  };

  const handleDashboard = () => {
    navigate('/admin');
    setAnchorEl(null);
    handleMenuClose();
  };

  const handleInstagram = () => {
    const instagramUrl = process.env.REACT_APP_INSTAGRAM_URL;
    if (instagramUrl) {
      window.open(instagramUrl, '_blank');
    } else {
      console.error("Instagram URL not defined");
    }
  };

  const handleFacebook = () => {
    const facebookUrl = process.env.REACT_APP_FACEBOOK_URL;
    if (facebookUrl) {
      window.open(facebookUrl, '_blank');
    } else {
      console.error("Facebook URL not defined");
    }
  };

  const handleYoutube = () => {
    const youtubeUrl = process.env.REACT_APP_YOUTUBE_URL;
    if (youtubeUrl) {
      window.open(youtubeUrl, '_blank');
    } else {
      console.error("YouTube URL not defined");
    }
  };
  const handleTimelineMessages = () => {
    navigate('/timeline');
    setAnchorEl(null);
    handleMenuClose();
  };

  const handlePayments = () => {
    navigate('/payments');
    setAnchorEl(null);
    handleMenuClose();
  };

  const renderMainMenu = (
    <Menu
      anchorEl={menuAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id="primary-main-menu"
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMainMenuOpen}
      onClose={handleMainMenuClose}
    >
      <MenuItem onClick={() => { navigate('/admissao'); handleMainMenuClose(); }}>Admissão</MenuItem>
      <MenuItem onClick={() => { navigate('/matriculas2025'); handleMainMenuClose(); }}>Matrículas 2025</MenuItem>
      <MenuItem onClick={() => { navigate('/nossa-escola'); handleMainMenuClose(); }}>Nossa Escola</MenuItem>
      <MenuItem onClick={() => { navigate('/niveis-de-ensino'); handleMainMenuClose(); }}>Níveis de Ensino</MenuItem>
      <MenuItem onClick={() => { navigate('/programas'); handleMainMenuClose(); }}>Programas</MenuItem>
      <MenuItem onClick={() => { navigate('/conteudos'); handleMainMenuClose(); }}>Conteúdos</MenuItem>
      <MenuItem onClick={() => { navigate('/area-do-responsavel'); handleMainMenuClose(); }}>Área do Responsável</MenuItem>
      <MenuItem onClick={() => { window.open('http://escola.computex.com.br:84/escola660/index2.php', '_blank'); handleMainMenuClose(); }}>Gestor Escolar</MenuItem>
      <MenuItem onClick={() => { navigate('/contato'); handleMainMenuClose(); }}>Contato</MenuItem>
    </Menu>
  );

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id="primary-search-account-menu"
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={naviggateToProfile}>Perfil</MenuItem>
      <MenuItem onClick={naviggateToHome}>Home</MenuItem>
      <MenuItem onClick={handleMenuClose}>Informes</MenuItem>
      <MenuItem onClick={handlePayments}>Pagamentos</MenuItem>
      {localStorage.getItem('user') && usertype === 'administrator' && (
        <MenuItem onClick={handleDashboard}>Dashboard</MenuItem>
      )}
      <MenuItem onClick={handleLoggout}>
        {localStorage.getItem('user') ? 'Sair' : 'Entrar'}
      </MenuItem>
    </Menu>
  );

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id="primary-search-account-menu-mobile"
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton size="large" aria-label="account of current user" aria-controls="primary-search-account-menu" aria-haspopup="true" color="inherit">
          <AccountCircle />
        </IconButton>
        <p>Perfil</p>
      </MenuItem>
      <MenuItem onClick={naviggateToHome}>
        <IconButton size="large" aria-label="home" aria-controls="primary-search-account-menu" aria-haspopup="true" color="inherit">
          <CottageIcon />
        </IconButton>
        <p>Home</p>
      </MenuItem>
      {/* <MenuItem onClick={handleTimelineMessages}>
        <IconButton size="large" aria-label="timeline messages" aria-controls="primary-search-account-menu" aria-haspopup="true" color="inherit">
          <MessageIcon />
        </IconButton>
        <p>Informes</p>
      </MenuItem> */}
      {/* <MenuItem onClick={handlePayments}>
        <IconButton size="large" aria-label="payments" aria-controls="primary-search-account-menu" aria-haspopup="true" color="inherit">
          <CreditCardIcon />
        </IconButton>
        <p>Pagamentos</p>
      </MenuItem> */}
      {localStorage.getItem('user') && (
        <MenuItem onClick={handleLoggout}>
          <IconButton size="large" aria-label="logout" aria-controls="primary-search-account-menu" aria-haspopup="true" color="inherit">
            <LogoutIcon />
          </IconButton>
          <p>Logout</p>
        </MenuItem>
      )}
      {!localStorage.getItem('user') && (
        <MenuItem onClick={() => navigate('/login')}>
          <IconButton size="large" aria-label="login" aria-controls="primary-search-account-menu" aria-haspopup="true" color="inherit">
            <LoginIcon />
          </IconButton>
          <p>Login</p>
        </MenuItem>
      )}
    </Menu>
  );

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" color="primary">
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{ mr: 2 }}
              onClick={handleMainMenuOpen}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{
                display: { xs: 'block', sm: 'block' },
                flexGrow: 1,
                textAlign: { xs: 'center', sm: 'left' },  // Centraliza o logo em telas pequenas
              }}
            >
              <Box
                component="img"
                src={Logo}
                alt="Logo"
                sx={{
                  height: 'auto',
                  maxHeight: { xs: 30, sm: 40 },  // Diminui a altura do logo para telas menores e maiores
                  width: 'auto',
                  maxWidth: '100%',  // Garante que o logo não ultrapasse o container
                }}
              />
            </Typography>

            <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
              <IconButton onClick={navigateToComputex} size="large">
                <img src={ComputexLogo} alt="Computex Logo" height={30} />
              </IconButton>
              <IconButton onClick={handleEmailClick} size="large">
                <EmailIcon />
              </IconButton>
            </Box>
            <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
              <Tooltip title="Instagram">
                <IconButton size="large" color="inherit" onClick={handleInstagram} sx={{ backgroundColor: 'transparent !important', color: 'inherit !important' }}>
                  <InstagramIcon className="icon-white" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Facebook">
                <IconButton size="large" color="inherit" onClick={handleFacebook} sx={{ backgroundColor: 'transparent !important', color: 'inherit !important' }}>
                  <FacebookIcon className="icon-white" />
                </IconButton>
              </Tooltip>
              <Tooltip title="YouTube">
                <IconButton size="large" color="inherit" onClick={handleYoutube} sx={{ backgroundColor: 'transparent !important', color: 'inherit !important' }}>
                  <YoutubeIcon className="icon-white" />
                </IconButton>
              </Tooltip>
            </Box>
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              <Tooltip title="Home">
                <IconButton size="large" color="inherit" onClick={naviggateToHome} sx={{ backgroundColor: 'transparent !important', color: 'inherit !important' }}>
                  <CottageIcon className="icon-white" />
                </IconButton>
              </Tooltip>
              {/* <Tooltip title="Timeline">
                <IconButton size="large" color="inherit" onClick={handleTimelineMessages} sx={{ backgroundColor: 'transparent !important', color: 'inherit !important' }}>
                  <MessageIcon className="icon-white" />
                </IconButton>
              </Tooltip> */}
              {/* <Tooltip title="Payments">
                <IconButton size="large" color="inherit" onClick={handlePayments} sx={{ backgroundColor: 'transparent !important', color: 'inherit !important' }}>
                  <CreditCardIcon className="icon-white" />
                </IconButton>
              </Tooltip> */}
              {localStorage.getItem('user') && usertype === 'administrator' && (
                <Tooltip title="Dashboard">
                  <IconButton size="large" color="inherit" onClick={handleDashboard} sx={{ backgroundColor: 'transparent !important', color: 'inherit !important' }}>
                    <DashboardIcon className="icon-white" />
                  </IconButton>
                </Tooltip>
              )}
              {localStorage.getItem('user') ? (
                <Tooltip title="Logout">
                  <IconButton size="large" color="inherit" onClick={handleLoggout} sx={{ backgroundColor: 'transparent !important', color: 'inherit !important' }}>
                    <LogoutIcon className="icon-white" />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Login">
                  <IconButton size="large" color="inherit" onClick={() => navigate('/login')} sx={{ backgroundColor: 'transparent !important', color: 'inherit !important' }}>
                    <LoginIcon className="icon-white" />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </Toolbar>
        </AppBar>
        {renderMenu}
        {renderMobileMenu}
        {renderMainMenu}
      </Box>
    </ThemeProvider>
  );
}

import React from 'react';
import styled from 'styled-components';
import GraphicsCard from '../graphics/GraphicsCard';
import EqualizerIcon from '@mui/icons-material/Equalizer';


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #F5F5F5;
`;

const Title = styled.h1`
  font-size: 32px;
  font-weight: bold;
  color: #333;
  margin-bottom: 30px;
`;

export default function Graphics() {
  return (
    <Wrapper>
      <Title><EqualizerIcon />Métricas</Title>
      <GraphicsCard />
    </Wrapper>
  );
}

import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import * as dotenv from 'dotenv';

dotenv.config();

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f5f5f5;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  width: 400px;

  @media (max-width: 480px) {
    width: 100%;
    padding: 10px;
  }
`;

const FormTitle = styled.h1`
  margin-bottom: 20px;
  color: #333;
`;

const Input = styled.input`
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
`;

const Select = styled.select`
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
`;

const Option = styled.option`
  font-size: 1rem;
`;

const Button = styled.button`
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  font-size: 1rem;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const NewUser = () => {
  const [formData, setFormData] = useState({
    username: '',
    name: '',
    email: '',
    usertype: '',
    password: '',
    tenantId: '',
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const api = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await api.post('/users', formData);
      alert('Usuário criado com sucesso!');
      navigate('/users');
    } catch (error) {
      console.error('Erro ao criar usuário:', error);
      alert('Houve um erro ao criar o usuário. Tente novamente.');
    }
  };

  return (
    <FormWrapper>
      <Form onSubmit={handleSubmit}>
        <FormTitle>Criar Novo Usuário</FormTitle>
        <Input
          type="text"
          name="username"
          placeholder="Nome de usuário"
          value={formData.username}
          onChange={handleChange}
          required
        />
        <Input
          type="text"
          name="name"
          placeholder="Nome completo"
          value={formData.name}
          onChange={handleChange}
          required
        />
        <Input
          type="email"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <Select
          name="usertype"
          value={formData.usertype}
          onChange={handleChange}
          required
        >
          <Option value="">Selecione o tipo de usuário</Option>
          <Option value="aluno">Aluno</Option>
          <Option value="administrator">Administrator</Option>
        </Select>
        <Input
          type="password"
          name="password"
          placeholder="Senha"
          value={formData.password}
          onChange={handleChange}
          required
        />
        <Input
          type="confirm-password"
          name="confirm-password"
          placeholder="Senha"
          value={formData.confirmpassword}
          onChange={handleChange}
          required
        />
        <Input
          type="text"
          name="tenantId"
          placeholder="ID do Inquilino"
          value={formData.tenantId}
          onChange={handleChange}
          required
        />
        <Button type="submit">Criar Usuário</Button>
      </Form>
    </FormWrapper>
  );
};

export default NewUser;

import React from "react";
import { Chart } from "react-google-charts";

export default function AccessTimeChart({ data }) {
  const chartData = [
    ["Horário", "Acessos"],
    ...data.map((entry) => {
      const { year, month, day, hour } = entry._id;
      const timeLabel = `${year}-${month}-${day} ${hour}:00`; // Formato de tempo legível
      return [timeLabel, entry.count];
    }),
  ];

  const options = {
    title: "Horários de Maior Acesso",
    hAxis: { title: "Horário", titleTextStyle: { color: "#333" } },
    vAxis: { title: "Acessos", minValue: 0 },
    chartArea: { width: "70%", height: "70%" },
  };

  return (
    <Chart
      chartType="AreaChart"
      width="100%"
      height="400px"
      data={chartData}
      options={options}
    />
  );
}

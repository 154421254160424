import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../../Contexts/auth';
import * as dotenv from 'dotenv';

dotenv.config();

function Professores() {
  const { user } = useContext(AuthContext);
  const [professores, setProfessores] = useState([]);
  const navigate = useNavigate();

  const API = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
  });

  useEffect(() => {
    const getProfessores = async () => {
      try {
        const { data: todosProfessores } = await API.get('/users', {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        });
        const filterProfessores = todosProfessores.filter(professor => professor.usertype === 'professor'); // Corrigido para professor.usertype
        const ordenedProfessores = filterProfessores.sort((a, b) => new Date(b.date) - new Date(a.date));
        setProfessores(ordenedProfessores);
      } catch (error) {
        console.error("Erro ao buscar alunos:", error);
      }
    }
    getProfessores();
  }, []);

  async function handleDelete(id) {
    if (window.confirm('Você tem certeza que deseja excluir este aluno?')) {
      await API.delete(`/teachers/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setProfessores(professores.filter(aluno => aluno.id !== id));
    }
  }

  function handleEdit(id) {
    navigate(`/professores/edit/${id}`);
  }

  if (user.usertype !== 'administrator') {
    return (
      <div>
        <h1>Você não tem permissão para acessar essa página.</h1>
      </div>
    );
  }

  return (
    <div>
      <h1 className='page-title'>Gerenciar Alunos</h1>
      <table className='alunos-table'>
        <thead>
          <tr>
            <th>Nome</th>
            <th>Email</th>
            <th>Turma</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {professores.map(professor => (
            <tr key={professor.id}>
              <td>{professor.name}</td>
              <td>{professor.email}</td>
              <td>{professor.classe}</td>
              <td>
                <button className='editBtn' onClick={() => handleEdit(professor.id)}>Editar</button>
                <button onClick={() => handleDelete(professor.id)}>Excluir</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Professores;

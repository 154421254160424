import React from 'react';
import { Container, Typography, Box, Grid, Paper, Card, CardMedia, CardContent } from '@mui/material';
import './OurSchool.css'; // Importando o arquivo CSS específico
import ensinoMedioImg from '../../images/EnsinoMedio.png';
import Infantil from '../../images/Educação Infantil.png';
import Fundamental1 from '../../images/Ens Fundamental 1.png';
import Fundamental2 from '../../images/Ens Fundamental 2.png';
import Medio from '../../images/EnsinoMedio.png';

const OurSchool = () => {

  const Fotos = [ensinoMedioImg, Infantil, Fundamental1, Fundamental2, Medio];

  return (
    <Container className="our-school-container">
      <Box mb={10} mt={10} textAlign="center" className="our-school-header">
        <Typography variant="h2" component="h1" gutterBottom>
          Nossa Escola
        </Typography>
        <Typography variant="h6" component="p" color="textSecondary">
          Conheça mais sobre nossa história, missão e instalações.
        </Typography>
      </Box>

      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} className="our-school-paper">
            <Typography variant="h4" component="h2" gutterBottom>
              Nossa História
            </Typography>
            <Typography variant="body1" component="p" gutterBottom>
              Fundada em 1980, nossa escola tem um legado de excelência educacional. Desde então, nos dedicamos a proporcionar um ensino de qualidade e um ambiente acolhedor para nossos alunos.
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper elevation={3} className="our-school-paper">
            <Typography variant="h4" component="h2" gutterBottom>
              Missão e Valores
            </Typography>
            <Typography variant="body1" component="p" gutterBottom>
              Nossa missão é formar cidadãos críticos e responsáveis, preparados para os desafios do futuro. Valorizamos a integridade, o respeito, a diversidade e a inovação.
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper elevation={3} className="our-school-paper">
            <Typography variant="h4" component="h2" gutterBottom>
              Nossas Instalações
            </Typography>
            <Typography variant="body1" component="p" gutterBottom>
              Oferecemos uma infraestrutura moderna e completa, incluindo:
            </Typography>
            <ul className="our-school-list">
              <li>Salas de aula equipadas com tecnologia de ponta.</li>
              <li>Biblioteca com um vasto acervo de livros e recursos digitais.</li>
              <li>Laboratórios de ciências e informática.</li>
              <li>Áreas de lazer e esportes.</li>
              <li>Ambientes acessíveis e inclusivos.</li>
            </ul>
          </Paper>
        </Grid>

        <Grid item xs={12} mt={10} mb={50}>
          <Typography variant="h4" component="h2" gutterBottom textAlign="center">
            Galeria de Fotos
          </Typography>
          <Grid container spacing={2} justifyContent="center">
            {Fotos.map((photo, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card>
                  <CardMedia
                    component="img"
                    alt={`Foto ${index + 1}`}
                    height="400"
                    image={photo}
                    style={{ cursor: 'pointer', objectFit: 'contain', padding: '8px' }}
                  />
                  <CardContent>
                    <Typography variant="body2" component="p">
                      {`Foto ${index + 1}`}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Box sx={{ height: '50px' }} /> {/* Espaço extra para evitar que encoste no footer */}
    </Container>
  );
};

export default OurSchool;

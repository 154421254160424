import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Card, CardMedia, CardContent, Button, Dialog, DialogActions, DialogContent, DialogTitle, Input, Snackbar, Container } from '@mui/material';
import axios from 'axios';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Alert from '@mui/material/Alert';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import ensinoMedioImg from '../../images/BannerPrincipalSite.png';
import FAQ from './FAQ';
import { Skeleton } from '@mui/material';
const CustomArrow = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.3);
  border: none;
  color: white;
  z-index: 1;
  padding: 5px;
  cursor: pointer;
  font-size: 18px;
  border-radius: 50%;
  ${props => props.right ? `right: 10px;` : `left: 10px;`}
  
  &:hover {
    background-color: rgba(0, 0, 0, 0.6);
  }

  @media (max-width: 768px) {
    ${props => props.right ? `right: 5px;` : `left: 5px;`}
  }
`;

const NextArrow = (props) => <CustomArrow right onClick={props.onClick}>&gt;</CustomArrow>;
const PrevArrow = (props) => <CustomArrow onClick={props.onClick}>&lt;</CustomArrow>;

const Campaigns = () => {
  const placeholderImage = 'https://via.placeholder.com/400x200';

  const [highlightPhotos, setHighlightPhotos] = useState(() => {
    const savedPhotos = localStorage.getItem('highlightPhotos');
    return savedPhotos ? JSON.parse(savedPhotos) : Array(2).fill(placeholderImage);
  });

  // Estado para o segundo slide de destaques com outra pasta no AWS
  const [secondaryHighlightPhotos, setSecondaryHighlightPhotos] = useState(() => {
    const savedPhotos = localStorage.getItem('secondaryHighlightPhotos');
    return savedPhotos ? JSON.parse(savedPhotos) : Array(2).fill(placeholderImage);
  });

  const [galleryPhotos, setGalleryPhotos] = useState(() => {
    const savedPhotos = localStorage.getItem('galleryPhotos');
    return savedPhotos ? JSON.parse(savedPhotos) : Array(3).fill(placeholderImage);
  });

  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentSelection, setCurrentSelection] = useState(null);
  const [currentType, setCurrentType] = useState(null);
  const [dialogImages, setDialogImages] = useState([]);
  const [uploadFile, setUploadFile] = useState(null);
  const [uploadError, setUploadError] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [userType, setUserType] = useState(null);
  const [loading, setLoading] = useState(true);
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const fetchImages = async (endpoint) => {
    try {
      const response = await axios.get(`${baseUrl}/uploads/images/${endpoint}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': '0'
        }
      });
      if (Array.isArray(response.data)) {
        return response.data.filter(image => image.url);
      } else {
        return [];
      }
    } catch (error) {
      return [];
    }
  };

  useEffect(() => {
    const fetchAllImages = async () => {
      try {
        const [highlightImages, galleryImages, secondaryHighlightImages] = await Promise.all([
          fetchImages('highlight'),
          fetchImages('gallery'),
          fetchImages('secondary-highlight') // Nova pasta no AWS
        ]);

        setHighlightPhotos(highlightImages.length > 0 ? highlightImages.map(image => image.url) : Array(2).fill(placeholderImage));
        setGalleryPhotos(galleryImages.length > 0 ? galleryImages.map(image => image.url) : Array(3).fill(placeholderImage));
        setSecondaryHighlightPhotos(secondaryHighlightImages.length > 0 ? secondaryHighlightImages.map(image => image.url) : Array(2).fill(placeholderImage));
      } catch (error) {
        console.error("Erro ao carregar imagens:", error);
      } finally {
        setLoading(false); // Aqui definimos o loading como false após a tentativa de carregar as imagens
      }
    };

    fetchAllImages();

    const fetchUserType = async () => {
      const savedUser = localStorage.getItem('user');
      const { usertype } = savedUser ? JSON.parse(savedUser) : {};
      setUserType(usertype);
    };

    fetchUserType();
  }, [baseUrl]);

  const fetchDialogImages = async (type) => {
    const images = await fetchImages(type);
    return images;
  };

  const openDialog = async (index, type) => {
    setCurrentSelection(index);
    setCurrentType(type);
    const images = await fetchDialogImages(type);
    setDialogImages(images);
    setDialogOpen(true);
  };

  const handleSelectPhoto = (url) => {
    if (currentType === 'highlight') {
      const newPhotos = [...highlightPhotos];
      newPhotos[currentSelection] = url;
      setHighlightPhotos(newPhotos);
      localStorage.setItem('highlightPhotos', JSON.stringify(newPhotos));
    } else if (currentType === 'gallery') {
      const newPhotos = [...galleryPhotos];
      newPhotos[currentSelection] = url;
      setGalleryPhotos(newPhotos);
      localStorage.setItem('galleryPhotos', JSON.stringify(newPhotos));
    } else if (currentType === 'secondary-highlight') { // Nova galeria
      const newPhotos = [...secondaryHighlightPhotos];
      newPhotos[currentSelection] = url;
      setSecondaryHighlightPhotos(newPhotos);
      localStorage.setItem('secondaryHighlightPhotos', JSON.stringify(newPhotos));
    }
    setDialogOpen(false);
  };

  const handleUpload = async () => {
    if (!uploadFile) {
      setUploadError('Nenhum arquivo selecionado.');
      return;
    }

    const formData = new FormData();
    formData.append('file', uploadFile);

    try {
      await axios.post(`${baseUrl}/uploads/images/${currentType}`, formData, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data'
        }
      });
      setUploadError('');
      setSnackbarOpen(true);

      const updatedImages = await fetchDialogImages(currentType);
      setDialogImages(updatedImages);

      if (currentType === 'gallery') {
        const updatedGalleryImages = await fetchImages('gallery');
        setGalleryPhotos(updatedGalleryImages.length > 0 ? updatedGalleryImages.map(image => image.url) : Array(3).fill(placeholderImage));
        localStorage.setItem('galleryPhotos', JSON.stringify(updatedGalleryImages));
      } else if (currentType === 'highlight') {
        const updatedHighlightImages = await fetchImages('highlight');
        setHighlightPhotos(updatedHighlightImages.length > 0 ? updatedHighlightImages.map(image => image.url) : Array(2).fill(placeholderImage));
        localStorage.setItem('highlightPhotos', JSON.stringify(updatedHighlightImages));
      } else if (currentType === 'secondary-highlight') { // Upload para a nova galeria
        const updatedSecondaryHighlightImages = await fetchImages('secondary-highlight');
        setSecondaryHighlightPhotos(updatedSecondaryHighlightImages.length > 0 ? updatedSecondaryHighlightImages.map(image => image.url) : Array(2).fill(placeholderImage));
        localStorage.setItem('secondaryHighlightPhotos', JSON.stringify(updatedSecondaryHighlightImages));
      }
    } catch (error) {
      setUploadError('Erro ao fazer upload da imagem.');
    }
  };

  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6, // Quantidade de imagens por vez em telas grandes
    slidesToScroll: 2, // Quantidade de imagens que rola por vez
    responsive: [
      {
        breakpoint: 1200, // Telas grandes (1200px ou mais)
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 992, // Telas médias (992px ou mais)
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768, // Telas pequenas (768px ou mais)
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576, // Telas muito pequenas (menos de 576px)
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1, // Exibe 1 slide em telas pequenas
          slidesToScroll: 1,
        },
      },
    ],
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const testimonials = [
    { 
      message: 'Eu recomendo o Colégio Manuel da Silva, porque foi onde eu e meu esposo estudamos, criando um vínculo de confiança e parceria durante anos... E que agora se estende para nossas filhas, como se fosse extensão da nossa família.Além da estrutura física e profissionais qualificados, o colégio é um ambiente muito acolhedor, eu indico e confio!', 
      name: 'Charlyanne Teixeira, mãe das alunas Sofia e Elisa♡' 
    },
    {
      message: 'Tenho um carinho imenso pelo Colégio Manuel da Silva, onde estudei minha vida inteira e criei memórias inesquecíveis. Meu irmão também estudou lá, e é por isso que hoje meus filhos seguem o mesmo caminho. Eu confio plenamente nos profissionais que trabalham no colégio, no ensino de qualidade que oferecem e na estrutura excelente do espaço. As crianças adoram estar lá, e eu sei que estão em boas mãos. Além disso, já trabalhei no colégio e posso dizer com segurança que ele tem uma missão clara e uma visão de futuro que admiro muito. Por tudo isso, eu amo o Colégio Manuel da Silva e tenho orgulho de ter essa instituição como parte da nossa história familiar.',
      name: 'Carol Marques, mãe do aluno Benjamim e da Aluna Maria Isabel'
    },
    {
      message: 'Sou apaixonada pela escola, vocês fazem tudo com amor e dedicação, por isso sai tudo tão perfeito! Obrigada por tudo!',
      name: 'Francisca Lenir, mãe do aluno aluno Gabriel Martins, 5º ano'
    },
    {
      message: 'Temos visto que os professores tem uma formação docente capacitada, a coordenação promove diálogos com as famílias dos alunos, existindo também um convívio bem amistoso entre funcionários, valorizando o convívio mútuo e o respeito entre todos.',
      name: 'Antônio Lúcio, pai da aluna Mirela, 4º ano.'
    },
    {
      message: 'Compartilhando a felicidade e a gratidão por essa escola maravilhosa. Troquei minha filha de escola contra a vontade dela e hoje ela está apaixonada pelo colégio.',
      name: 'Daniele Rodrigues Campos, mãe da aluna Emily , 2º ano.'
    },
  ];

  const EducationValueSection = () => {
    const navigate = useNavigate();

    const handleLeiaMaisClick = () => {
      navigate('/nossa-escola');
    };

    return (
      <Box
        mb={6}
        mt={10}
        sx={{
          width: '90%',
          backgroundColor: '#e0f7fa',
          padding: '40px',
          borderRadius: '8px',
          color: '#004d40',
          position: 'relative',
          mx: 'auto',
        }}
      >
        <Typography variant="h4" component="h3" gutterBottom>
          Educação de Valor
        </Typography>
        <Typography variant="body1" component="p">
          No Colégio MS oferecemos uma educação que promove não apenas o conhecimento acadêmico, mas também o desenvolvimento pessoal e social dos alunos. Com um currículo diversificado e atividades extracurriculares enriquecedoras, nossos alunos são preparados para enfrentar desafios e se destacar em suas futuras carreiras.
        </Typography>
        <Typography variant="body1" component="p">
          Nossa missão é oferecer um espaço de convivência que propicie resultados satisfatórios e a formação integral dos alunos, utilizando para este fim talentos humanos e tecnologias avançada.
          Acreditamos na importância de valores como respeito, responsabilidade e empatia, que são integrados ao nosso ensino diário.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{
            position: 'absolute',
            bottom: '20px',
            right: '20px',
          }}
          onClick={handleLeiaMaisClick}
        >
          Leia Mais
        </Button>
      </Box>
    );
  };

  const EducationLevelsSection = () => (
    <Box mb={6} mt={10} sx={{ width: '90%', mx: 'auto' }}>
      <Typography variant="h4" component="h3" gutterBottom>
        Níveis de Ensino
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" component="h2">
                Educação Infantil
              </Typography>
              <Typography variant="body2" component="p">
                Oferecemos um ambiente seguro e estimulante para as crianças, com atividades que promovem o desenvolvimento cognitivo e social.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" component="h2">
                Ensino Fundamental
              </Typography>
              <Typography variant="body2" component="p">
                Nosso currículo é projetado para fornecer uma base sólida de conhecimento, com ênfase em habilidades críticas e criativas.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" component="h2">
                Ensino Médio
              </Typography>
              <Typography variant="body2" component="p">
                Preparamos nossos alunos para os desafios do ensino superior e do mercado de trabalho, com um currículo focado em excelência acadêmica.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );

  const LocationsSection = () => (
    <Box mb={6} mt={10} sx={{ width: '100%', mx: 'auto' }}>
      <Typography variant="h4" component="h3" gutterBottom paddingLeft={12}>
        Nossa Localização
      </Typography>
      <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
        <GoogleMap
          mapContainerStyle={{ height: '500px', width: '100%' }}
          center={{ lat: -3.745, lng: -38.523 }}
          zoom={12}
        >
          <Marker position={{ lat: -3.745, lng: -38.523 }} label="Sede 1" />
          <Marker position={{ lat: -3.735, lng: -38.533 }} label="Sede 2" />
        </GoogleMap>
      </LoadScript>
    </Box>
  );

  return (
    <Box sx={{ width: '100%', paddingBottom: '0px' }}>
      {/* Seção de Boas-vindas */}
      <Box
        className="welcome-section"
        mb={6}
        mt={10}
        sx={{
          width: '100%',
          backgroundImage: `url(${ensinoMedioImg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          textAlign: 'center',
          color: '#4B90E0',
          padding: '60px 0',
        }}
      >
        <Typography
          variant="h1"
          component="h1"
          gutterBottom
          sx={{
            fontSize: {
              xs: '2rem',
              sm: '2.5rem',
              md: '3rem',
              lg: '4rem',
            },
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            padding: '10px 20px',
            borderRadius: '8px',
          }}
        >
          Bem-vindo à nossa escola!
        </Typography>
        <Typography variant="h6" component="p">
          Estamos comprometidos em fornecer uma educação de qualidade e um ambiente acolhedor para todos os alunos.
        </Typography>
      </Box>


      {/* Seção de Destaques */}
      <Box mb={6} mt={10} sx={{ width: '90%', mx: 'auto' }}>
        <Typography variant="h4" component="h3" gutterBottom>
          Destaques MS
        </Typography>
        <Grid container spacing={2}>
          {loading
            ? Array(3).fill().map((_, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Skeleton variant="rectangular" width="100%" height={250} />
              </Grid>
            ))
            : highlightPhotos.map((photo, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card>
                  <CardMedia
                    component="img"
                    alt={`Destaque ${index + 1}`}
                    height="250"
                    image={photo}
                    onClick={() => userType === 'administrator' && openDialog(index, 'highlight')}
                    className="card-highlight"
                    style={{
                      cursor: 'pointer',
                      objectFit: 'contain',
                      width: '100%',
                      paddingTop: '10px',
                      backgroundColor: '#f0f0f0',
                    }}
                  />
                  <CardContent>
                    <Typography variant="h6" component="p">
                      {/* Título ou descrição opcional */}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
        </Grid>
      </Box>

      {/* Seção de Galeria */}
      <Box mb={6} sx={{ width: '90%', mx: 'auto', overflow: 'hidden' }}>
        <Typography variant="h4" component="h3" gutterBottom>
          Resultados MS
        </Typography>

        {/* Slider principal */}
        <Slider {...carouselSettings} key={loading ? 'loading' : 'loaded'}>
          {loading
            ? Array(6)
              .fill()
              .map((_, index) => (
                <div key={index} style={{ padding: '10px' }}>
                  <Card
                    style={{
                      borderRadius: '10px',
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                      margin: '10px',
                      height: '250px',
                      width: '100%',
                      maxWidth: '350px',
                      position: 'relative', // Adicionado para posicionamento absoluto
                      overflow: 'hidden',   // Garante que o conteúdo não exceda o Card
                    }}
                  >
                    <Skeleton variant="rectangular" width="100%" height="100%" />
                  </Card>
                </div>
              ))
            : galleryPhotos.map((photo, index) => (
              <div key={index} style={{ padding: '10px' }}>
                <Card
                  style={{
                    borderRadius: '10px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    margin: '10px',
                    height: '250px',
                    width: '100%',
                    maxWidth: '350px',
                    position: 'relative', // Adicionado para posicionamento absoluto
                    overflow: 'hidden',   // Garante que o conteúdo não exceda o Card
                  }}
                >
                  <CardMedia
                    component="img"
                    alt={`Galeria ${index + 1}`}
                    image={photo}
                    onClick={() => userType === 'administrator' && openDialog(index, 'gallery')}
                    sx={{
                      cursor: 'pointer',
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      width: 'auto',
                      height: '100%',
                      transform: 'translate(-50%, -50%)',
                      objectFit: 'contain',
                    }}
                  />
                </Card>
              </div>
            ))}
        </Slider>

        {/* Botão para adicionar novas imagens na galeria */}
        {userType === 'administrator' && (
          <Box textAlign="center" mt={2}>
            <Button variant="contained" color="primary" onClick={() => openDialog(null, 'gallery')}>
              Adicionar Imagem na Galeria
            </Button>
          </Box>
        )}

        {/* Slider secundário */}
        <Slider {...carouselSettings} key={loading ? 'loading-secondary' : 'loaded-secondary'}>
          {loading
            ? Array(6)
              .fill()
              .map((_, index) => (
                <div key={index} style={{ padding: '10px' }}>
                  <Card
                    style={{
                      borderRadius: '10px',
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                      margin: '10px',
                      height: '250px',
                      width: '100%',
                      maxWidth: '350px',
                      position: 'relative', // Adicionado para posicionamento absoluto
                      overflow: 'hidden',   // Garante que o conteúdo não exceda o Card
                    }}
                  >
                    <Skeleton variant="rectangular" width="100%" height="100%" />
                  </Card>
                </div>
              ))
            : secondaryHighlightPhotos.map((photo, index) => (
              <div key={index} style={{ padding: '10px' }}>
                <Card
                  style={{
                    borderRadius: '10px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    margin: '10px',
                    height: '250px',
                    width: '100%',
                    maxWidth: '350px',
                    position: 'relative', // Adicionado para posicionamento absoluto
                    overflow: 'hidden',   // Garante que o conteúdo não exceda o Card
                  }}
                >
                  <CardMedia
                    component="img"
                    alt={`Galeria Secundária ${index + 1}`}
                    image={photo}
                    onClick={() => userType === 'administrator' && openDialog(index, 'secondary-highlight')}
                    sx={{
                      cursor: 'pointer',
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      width: 'auto',
                      height: '100%',
                      transform: 'translate(-50%, -50%)',
                      objectFit: 'contain',
                    }}
                  />
                </Card>
              </div>
            ))}
        </Slider>

        {/* Botão para adicionar novas imagens na galeria secundária */}
        {userType === 'administrator' && (
          <Box textAlign="center" mt={2}>
            <Button variant="contained" color="primary" onClick={() => openDialog(null, 'secondary-highlight')}>
              Adicionar Imagem de Destaque Secundário
            </Button>
          </Box>
        )}
      </Box>

  {/* Seção de Depoimentos */}
<Box
  mb={6}
  sx={{
    width: '90%',
    mx: 'auto',
    backgroundColor: '#f0f0f0', // Adiciona a cor de fundo
    padding: '20px', // Adiciona padding para espaçamento interno
    borderRadius: '8px', // Adiciona bordas arredondadas
    display: 'flex', // Define o display como flex
    flexDirection: 'column', // Garante que os filhos sejam organizados em coluna
    justifyContent: 'center', // Centraliza verticalmente
    minHeight: '200px', // Define uma altura mínima para garantir o alinhamento vertical
  }}
>
  <Typography
    variant="h4"
    component="h3"
    gutterBottom
    sx={{
      textAlign: 'left', // Ajusta o texto à esquerda
      fontSize: {
        xs: '1.5rem', // Tamanho da fonte em telas pequenas
        sm: '1.8rem', // Tamanho da fonte em telas médias
        md: '2rem', // Tamanho da fonte em telas grandes
        lg: '2.5rem', // Tamanho da fonte em telas maiores
      },
    }}
  >
    Depoimentos
  </Typography>
  <Slider {...settings}>
    {testimonials.map((testimonial, index) => (
      <div key={index}>
        <Box
          className="testimonial"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            minHeight: '150px', // Altura mínima para centralizar verticalmente
          }}
        >
          <Typography
            variant="h6"
            component="p"
            sx={{
              fontStyle: 'italic',
              fontWeight: 'bold',
              color: '#6bc4ca',
              fontSize: {
                xs: '1rem', // Tamanho da fonte em telas pequenas
                sm: '1.2rem', // Tamanho da fonte em telas médias
                md: '1.4rem', // Tamanho da fonte em telas grandes
                lg: '1.6rem', // Tamanho da fonte em telas maiores
              }
            }}
          >
            "{testimonial.message}"
          </Typography>
          <Typography
            variant="subtitle1"
            component="p"
            sx={{
              fontStyle: 'italic',
              fontWeight: 'bold',
              color: '#6bca9e',
              fontSize: {
                xs: '0.8rem', // Tamanho da fonte em telas pequenas
                sm: '1rem', // Tamanho da fonte em telas médias
                md: '1.2rem', // Tamanho da fonte em telas grandes
                lg: '1.4rem', // Tamanho da fonte em telas maiores
              }
            }}
          >
            - {testimonial.name}
          </Typography>
        </Box>
      </div>
    ))}
  </Slider>
</Box>


      {/* Modal de Seleção de Imagem */}
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>Selecionar Imagem</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {dialogImages.map((image, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card>
                  <CardMedia
                    component="img"
                    alt={`Imagem ${index + 1}`}
                    height="200"
                    image={image.url}
                    onClick={() => handleSelectPhoto(image.url)}
                    className="card-dialog"
                    style={{ cursor: 'pointer' }}
                  />
                </Card>
              </Grid>
            ))}
          </Grid>
          <Box mt={2}>
            <Input type="file" onChange={(e) => setUploadFile(e.target.files[0])} />
            {uploadError && <Typography color="error">{uploadError}</Typography>}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUpload} color="primary" disabled={!uploadFile}>
            Enviar
          </Button>
          <Button onClick={() => setDialogOpen(false)} color="secondary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar de Sucesso */}
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity="success">
          Imagem enviada com sucesso!
        </Alert>
      </Snackbar>

      {/* Seções Adicionais */}
      <EducationValueSection />
      <EducationLevelsSection />
      <FAQ />
      <LocationsSection />
    </Box>
  );
};

export default Campaigns;

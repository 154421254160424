import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { Container, Typography, TextField, Button, Grid, Paper, Box } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import SendIcon from '@mui/icons-material/Send';

const theme = createTheme({
  palette: {
    primary: {
      main: '#f44336',
    },
    secondary: {
      main: '#ffffff',
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
});

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs.send('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', formData, 'YOUR_USER_ID')
      .then((response) => {
        console.log('Email sent successfully:', response.status, response.text);
      }, (error) => {
        console.log('Failed to send email:', error);
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="md" sx={{ py: 4, mb: 20 }}>
        <Typography variant="h4" gutterBottom>
          <ContactMailIcon sx={{ mr: 1 }} />
          Contato
        </Typography>
        <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
          <Typography variant="h6" gutterBottom>
            Entre em contato conosco
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Nome"
                  variant="outlined"
                  name="name"
                  required
                  value={formData.name}
                  onChange={handleChange}
                  sx={{ '& .MuiOutlinedInput-root': { borderRadius: 2 } }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Email"
                  variant="outlined"
                  name="email"
                  type="email"
                  required
                  value={formData.email}
                  onChange={handleChange}
                  sx={{ '& .MuiOutlinedInput-root': { borderRadius: 2 } }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Assunto"
                  variant="outlined"
                  name="subject"
                  required
                  value={formData.subject}
                  onChange={handleChange}
                  sx={{ '& .MuiOutlinedInput-root': { borderRadius: 2 } }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Mensagem"
                  variant="outlined"
                  name="message"
                  multiline
                  rows={4}
                  required
                  value={formData.message}
                  onChange={handleChange}
                  sx={{ '& .MuiOutlinedInput-root': { borderRadius: 2 } }}
                />
              </Grid>
              <Grid item xs={12} textAlign="center">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  endIcon={<SendIcon />}
                  sx={{
                    textTransform: 'none',
                    borderRadius: 2,
                    px: 4,
                    py: 1
                  }}
                >
                  Enviar
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
        <Box sx={{ mt: 6, p: 3, mb: 20, borderRadius: 2, backgroundColor: '#f9f9f9', color: '#000' }}>
          <Typography variant="h6" gutterBottom>
            Informações de Contato
          </Typography>
          <Typography variant="body1" gutterBottom>
            <strong>Telefone:</strong> (85) 3228.3418 / (85) 98766.0739
          </Typography>
          <Typography variant="body1" gutterBottom>
            <strong>Email:</strong> secretaria@manueldasilva.com.br
          </Typography>
          <Typography variant="body1">
            <strong>Endereço:</strong> Endereço: R. Assis Bezerra, 25, Álvaro Weyne Fortaleza - Ceará
          </Typography>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default ContactPage;

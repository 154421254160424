import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../Contexts/auth";
import { useNavigate } from "react-router-dom";
import { getUsers } from "../../Services/api";
import styled from "styled-components";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const UsersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  margin-bottom: 150px;
`;

const UserItem = styled.div`
  border: 1px solid #ccc;
  background-color: #c90f0f;
  border-radius: 8px;
  padding: 16px;
  margin: 16px 0;
  width: 100%;
  max-width: 600px;

  &:hover {
    background-color: #DE012D;
    cursor: pointer;
  }

  & h2 {
    margin: 0;
    font-size: 24px;
    font-weight: bold;
  }

  & p {
    margin: 8px 0 0;
    font-size: 16px;
  }
`;

const Users = () => {
  const { user } = useContext(AuthContext);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  
  const navigate = useNavigate();
  
  const handleEdit = ({ id }) => {
    console.log('EditUser');
    navigate(`/users/${id}/edituser`);
  };
  
  const handleDelete = ({ id }) => {
    console.log('DeleteUser');
    navigate(`/users/${id}/deleteuser`);
  };
  
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await getUsers();
        setUsers(response);
        console.log(response);
      } catch (error) {
        console.error("Erro ao buscar usuários:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchUsers();
  }, []);
  
  if(user.usertype === 'administrator') {
    return (
      <UsersWrapper>
        <h1>Usuários</h1>
        <h5>Você tem permissões de {user.usertype}.</h5>
        {loading ? (
          <div style={{ width: '100%', maxWidth: '600px' }}>
            <Skeleton height={40} count={5} style={{ marginBottom: '16px' }} />
          </div>
        ) : (
          users.map((user) => (
            <UserItem key={user._id}>
              <h2>{user.name}</h2>
              <p>Username: {user.username}</p>
              <p>E-mail: {user.email}</p>
              <p>Permissões: {user.usertype}</p>
              <button onClick={() => handleEdit({ id: user._id })}>Editar</button>
              <button onClick={() => handleDelete({ id: user._id })}>Excluir</button>
            </UserItem>
          ))
        )}
      </UsersWrapper>
    );
  } else {
    return (
      <UsersWrapper>
        <h1>Usuários</h1>
        <h5>Você não tem permissões de administrador.</h5>
      </UsersWrapper>
    );
  }
};

export default Users;

import React from 'react';
import { Container, Typography, Paper, Grid, Box, Card, CardMedia, CardContent } from '@mui/material';
import SchoolIcon from '@mui/icons-material/School';
import infantilImg from '../../images/Educação Infantil.png';
import fundamental1Img from '../../images/Ens Fundamental 1.png';
import fundamental2Img from '../../images/Ens Fundamental 2.png';
import medioImg from '../../images/EnsinoMedio.png';

const EducationLevelsPage = () => {
  const educationLevels = [
    {
      title: "Educação Infantil",
      description: "A Educação Infantil é uma etapa fundamental para o desenvolvimento das crianças, onde trabalhamos o desenvolvimento emocional, social e cognitivo por meio de atividades lúdicas e pedagógicas.",
      image: infantilImg,
    },
    {
      title: "Ensino Fundamental I",
      description: "O Ensino Fundamental é dividido em anos iniciais e finais, com foco no desenvolvimento das competências básicas de leitura, escrita, matemática e ciências. Utilizamos métodos modernos e recursos didáticos para proporcionar um aprendizado significativo.",
      image: fundamental1Img,
    },
    {
      title: "Ensino Fundamental II",
      description: "Continuamos a construir sobre a base do Ensino Fundamental I, preparando os alunos para os desafios do Ensino Médio com uma abordagem ainda mais focada em habilidades críticas e criativas.",
      image: fundamental2Img,
    },
    {
      title: "Ensino Médio",
      description: "O Ensino Médio prepara os alunos para o ingresso no ensino superior e para o mercado de trabalho, com uma abordagem que integra teoria e prática, promovendo o pensamento crítico e a formação cidadã.",
      image: medioImg,
    },
  ];

  return (
    <Container maxWidth="md" sx={{ py: 4, pb: { xs: 20, md: 30 } }}>
      <Box textAlign="center" mb={4}>
        <Typography variant="h4" gutterBottom>
          <SchoolIcon sx={{ mr: 1 }} />
          Níveis de Ensino
        </Typography>
      </Box>
      <Grid container spacing={4}>
        {educationLevels.map((level, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Paper elevation={3} sx={{ p: { xs: 2, md: 3 }, height: '100%' }}>
              <Typography variant="h6" gutterBottom>
                {level.title}
              </Typography>
              <Typography variant="body1" gutterBottom>
                {level.description}
              </Typography>
              <Card sx={{ maxWidth: '100%', margin: 'auto' }}>
                <CardMedia
                  component="img"
                  alt={level.title}
                  height="400"
                  image={level.image}
                  style={{ objectFit: 'contain' }} // Ajustando para manter a imagem contida no box
                />
                <CardContent>
                  <Typography variant="body2" component="p">
                    {`Foto ${index + 1}`}
                  </Typography>
                </CardContent>
              </Card>
            </Paper>
          </Grid>
        ))}
      </Grid>
      <Box sx={{ height: '50px', margin: '40px' }} /> {/* Espaço extra para evitar que encoste no footer */}
    </Container>
  );
};

export default EducationLevelsPage;

import React from 'react';
import { Container, Typography, Paper, Box, Grid } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';

const ResponsiblesAreaPage = () => {
  return (
    <Container maxWidth="md" sx={{ py: 4, mb:60 }}>
      <Typography variant="h4" gutterBottom>
        <PeopleIcon sx={{ mr: 1 }} />
        Área do Responsável
      </Typography>
      <Paper elevation={3} sx={{ p: 3 }}>
        <Typography variant="h6" gutterBottom>
          Acesso para Responsáveis
        </Typography>
        <Typography variant="body1" paragraph>
          Nesta área, os responsáveis podem acompanhar o desempenho acadêmico dos alunos, consultar notas, acessar relatórios e ficar atualizados sobre a agenda escolar.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Funcionalidades
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Paper elevation={2} sx={{ p: 2 }}>
              <Typography variant="h6">
                Consultar Notas
              </Typography>
              <Typography variant="body1">
                Acesse as notas e o desempenho acadêmico do aluno em tempo real.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper elevation={2} sx={{ p: 2 }}>
              <Typography variant="h6">
                Relatórios
              </Typography>
              <Typography variant="body1">
                Receba relatórios detalhados sobre o progresso e desenvolvimento do aluno.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper elevation={2} sx={{ p: 2 }}>
              <Typography variant="h6">
                Agenda Escolar
              </Typography>
              <Typography variant="body1">
                Fique por dentro dos eventos e atividades programadas.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper elevation={2} sx={{ p: 2 }}>
              <Typography variant="h6">
                Comunicação
              </Typography>
              <Typography variant="body1">
                Envie mensagens e se comunique diretamente com a equipe escolar.
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default ResponsiblesAreaPage;

// Financeiro.jsx métodos de pagamentos
import React from 'react';
import styled from 'styled-components';

const FinanceiroContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem ;
  color: #fff;
  background-color: #DE012D;
`;



const Financeiro = () => {
  return (
    <FinanceiroContainer>
      <h1>Financeiro</h1>
      <h3>Em Construção</h3>
    </FinanceiroContainer>
  );
}

export default Financeiro;
import React, { useState, useContext } from "react";
import './styles.css';
import { AuthContext } from "../../Contexts/auth";
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
  const { authenticated, login } = useContext(AuthContext);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('submit', { username, password });
    login(username, password);
  };

  const navigate = useNavigate();
  const handleRegister = () => {
    console.log('register');
    navigate('/register');
  };

  return (
    <div id="login">
      <h1 className="title">Login do Sistema</h1>
      {/* <p>{String(authenticated)}</p> */}
      <form className="form" onSubmit={handleSubmit}>
        <div className="field">
          <label htmlFor="username">Nome de Usuário</label>
          <input
            type="text"
            name="username"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="field">
          <label htmlFor="password">Senha</label>
          <input
            type="password"
            name="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="actions">
          <button type="submit">Entrar</button>
          <button type="button">Esqueci minha senha</button>
          <button type="button" onClick={handleRegister}>Cadastrar</button>
        </div>
      </form>
    </div>
  );
}

export default LoginPage;

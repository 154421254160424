import React, { useEffect, useState, useMemo } from "react";
import axios from 'axios';
import PostCard from '../../../components/PostCard/PostCard';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import '../turmas.css';
import dotenv from 'dotenv';

dotenv.config();

function Infantil1() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  const API = useMemo(() => axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
  }), []);

  useEffect(() => {
    const getPosts = async () => {
      try {
        const { data: allPosts } = await API.get('/posts', {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        });
        const classPosts = allPosts.filter(post => post.classId === 'Infantil-I');
        const sortedPosts = classPosts.sort((a, b) => new Date(b.date) - new Date(a.date));
        setPosts(sortedPosts);
      } catch (error) {
        console.error("Erro ao buscar posts:", error);
      } finally {
        setLoading(false);
      }
    }
    getPosts();
  }, [API]);

  return (
    <div>
      <h1 className="title">Infantil-II</h1>
      <div className="post-content">
        {loading ? (
          <Skeleton count={5} height={150} style={{ marginBottom: '1rem', borderRadius: '8px' }} />
        ) : (
          posts.map((post, index) => (<PostCard key={index} post={post} />))
        )}
      </div>
    </div>
  );
}

export default Infantil1;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Card from '../../components/card/Card';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import AnnouncementIcon from '@mui/icons-material/Announcement';

const SearchContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const SearchInput = styled.input`
  padding: 10px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 300px;

  &:focus {
    outline: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  }

  &::placeholder {
    color: #aaa;
  }
`;

const CardContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const InformContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
  background-color: #f44336;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  transition: transform 0.2s ease-in-out;
  width: 93%;

  &:focus {
    outline: none;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
  }

  &::placeholder {
    color: #aaa;
  }

  &:hover {
    transform: scale(1.01);
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.6);
  }
`;

const InformTitle = styled.h1`
  align-items: center;
  margin: 1rem;
  padding-top: 1rem;
  display: flex;
  text-align: center; 
  justify-content: center; 
  font-size: 2.5rem; 
  font-weight: bold; 
  color: #fff;
`;

const InformContent = styled.p`
  align-items: center;
  margin: 1rem;
  padding-bottom: 1rem;
  display: flex;
  text-align: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: #ffffff;
`;

function CreateTurmas() {
  const [turmas, setTurmas] = useState([]);
  const [filtro, setFiltro] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const baseUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const getTurmas = async () => {
      try {
        const { data: turmas } = await axios.get(`${baseUrl}/classes`);
        if (Array.isArray(turmas)) {
          setTurmas(turmas);
        } else {
          console.error('Resposta da API não é uma array:', turmas);
          setTurmas([]);
        }
      } catch (error) {
        console.error('Erro ao buscar turmas:', error);
        setTurmas([]);
      } finally {
        setLoading(false);
      }
    };
    getTurmas();
  }, [baseUrl]);

  const turmasFiltradas = Array.isArray(turmas) ? turmas.filter(turma => {
    return turma.name.toLowerCase().includes(filtro.toLowerCase());
  }) : [];

  const handleInformes = () => {
    navigate('/timeline');
  };

  return (
    <>
      <SearchContainer>
        <SearchInput
          type="text"
          value={filtro}
          onChange={(e) => setFiltro(e.target.value)}
          placeholder="Buscar turma"
        />
      </SearchContainer>
      <InformContainer onClick={handleInformes}>
        <InformTitle><AnnouncementIcon sx={{ fontSize: 60 }} />Informes</InformTitle>
        <InformContent>Clique Aqui</InformContent>
      </InformContainer>
      <CardContent>
        {loading ? (
          <div>Carregando...</div>
        ) : turmasFiltradas.length > 0 ? (
          turmasFiltradas.map((turma, index) => (
            <Card key={index} turma={turma} />
          ))
        ) : (
          <div>Nenhuma turma encontrada</div>
        )}
      </CardContent>
    </>
  );
}

export default CreateTurmas;

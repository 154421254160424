import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';

const Container = styled.div`
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
  margin-bottom:250px;
`;

const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 1rem;
  text-align: center;
  color: #333;
`;

const UploadSection = styled.div`
  margin-bottom: 2rem;
  text-align: center;
`;

const FileInput = styled.input`
  margin-right: 1rem;
`;

const Button = styled.button`
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1rem;
  margin: 0.5rem;
  cursor: pointer;
  border-radius: 0.25rem;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #45a049;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const Loader = styled.p`
  text-align: center;
  font-size: 1.2rem;
  color: #666;
`;

const List = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const ListItem = styled.li`
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: ${(props) => (props.isClicked ? '#d4edda' : '#f8d7da')}; // verde se clicado, vermelho se não
  color: ${(props) => (props.isClicked ? '#155724' : '#721c24')}; // texto verde se clicado, vermelho se não

  h2 {
    margin-top: 0;
  }

  a {
    color: ${(props) => (props.isClicked ? '#0056b3' : '#007BFF')}; // link azul mais escuro se clicado, azul padrão se não
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const Activities = () => {
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [clickedItems, setClickedItems] = useState({}); // Estado para controlar quais itens foram clicados

  const baseUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    // Recupera o estado clicado do localStorage
    const savedClickedItems = localStorage.getItem('clickedItems');
    if (savedClickedItems) {
      setClickedItems(JSON.parse(savedClickedItems));
    }
  }, []);

  const fetchActivities = async () => {
    console.log('token', localStorage.getItem('token'));
    setLoading(true);
    try {
      const url = `${baseUrl}/uploads/activities`;
      console.log('Fetching activities from url:', url); // Log da URL
      const response = await axios.get(url, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': '0'
        }
      });
      console.log('Response.data', response.data);
      if (Array.isArray(response.data)) {
        setActivities(response.data);
      } else {
        console.log('Response.data', response.data);
        console.error('Resposta da API não é uma array:', response.data);
        setActivities([]);
      }
    } catch (error) {
      console.error('Erro ao buscar as atividades:', error);
      setActivities([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log('Base URL:', baseUrl); // Verifique a URL base
    fetchActivities();
  }, [baseUrl]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      alert('Por favor, selecione um arquivo primeiro.');
      return;
    }
    // limpando o campo de upload
    document.querySelector('input[type="file"]').value = '';

    const formData = new FormData();
    formData.append('file', file);

    setLoading(true);
    try {
      await axios.post(`${baseUrl}/uploads/activities`, formData, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data'
        }
      });
      // Re-fetch activities after upload
      fetchActivities();
      alert('Arquivo enviado com sucesso!');
    } catch (error) {
      console.error('Erro ao fazer upload do arquivo:', error);
      alert('Erro ao fazer upload do arquivo.');
    } finally {
      setLoading(false);
    }
  };

  const handleItemClick = (filename) => {
    const newClickedItems = {
      ...clickedItems,
      [filename]: !clickedItems[filename], // Alterna o estado do item clicado
    };
    setClickedItems(newClickedItems);
    localStorage.setItem('clickedItems', JSON.stringify(newClickedItems)); // Salva o estado clicado no localStorage
  };

  return (
    <Container>
      <Title>Atividades</Title>
      <UploadSection>
        <FileInput type="file" onChange={handleFileChange} />
        <Button onClick={handleUpload} disabled={loading}>
          {loading ? 'Carregando...' : 'Enviar Arquivo'}
        </Button>
      </UploadSection>
      {loading && <Loader>Carregando...</Loader>}
      <List>
        {activities.map((activity) => (
          <ListItem
            key={activity.filename}
            isClicked={clickedItems[activity.filename]} // Passa o estado clicado para o item
            onClick={() => handleItemClick(activity.filename)} // Adiciona manipulador de clique
          >
            <h2>{activity.filename}</h2>
            <a href={activity.url} target="_blank" rel="noopener noreferrer">Ver Atividade</a>
          </ListItem>
        ))}
      </List>
    </Container>
  );
};

export default Activities;

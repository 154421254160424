import React from "react";
import { Chart } from "react-google-charts";

export const options = {
  title: "My Daily Activities",
};

export default function Pizza({ data }) {
  const chartData = [["Usuário", "Total"]];

  data.datasets[0].data.forEach((value, index) => {
    chartData.push([data.labels[index], value]);
  });

  return (
    <Chart
      chartType="PieChart"
      data={chartData}
      options={options}
      width={"100%"}
      height={"400px"}
    />
  );
}

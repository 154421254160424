import React from "react";
import { Chart } from "react-google-charts";

export default function Bar({ data }) {
  // Verifica se os dados são um array e se estão no formato correto
  if (!Array.isArray(data.labels) || !Array.isArray(data.datasets) || !data.datasets[0].data) {
    console.error('Dados inválidos fornecidos para o gráfico:', data);
    return <div>Dados inválidos para o gráfico.</div>;
  }

  // Converte os dados em um formato aceito pelo Google Charts
  const chartData = [
    ["Data", "Acessos"],
    ...data.labels.map((label, index) => [label, data.datasets[0].data[index]]),
  ];

  const options = {
    title: "Acesso de Usuários",
    hAxis: { title: "Data e Hora", titleTextStyle: { color: "#333" } },
    vAxis: { title: "Acessos", minValue: 0 },
    chartArea: { width: "70%", height: "70%" },
  };

  return (
    <Chart
      chartType="Bar"
      width="100%"
      height="400px"
      data={chartData}
      options={options}
    />
  );
}

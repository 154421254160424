// DeleteUser.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import * as dotenv from 'dotenv';

dotenv.config();

function DeleteUser() {
  const { id } = useParams();
  const [name, setName] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [photo, setPhoto] = useState('');
  const [usertype, setUsertype] = useState('');
  const navigate = useNavigate();

    const API = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
    });

    useEffect(() => {
      async function fetchUser() {
        const response = await API.get(`/user/${id}`);
        console.log(id)
        console.log(response);
        const { name, username, email, photo, usertype } = response.data;
        setName(name);
        setUsername(username);
        setEmail(email);
        setPhoto(photo);
        setUsertype(usertype);
      }
      fetchUser();
    }, [id]);

    async function handleSubmit(e) {
      e.preventDefault();

      // alert(`Tem certeza que deseja excluir este post de ID ${id}?`)

      // excluir um post com autenticação
      const token = localStorage.getItem('token');
      const response = await API.delete(`/user/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      console.log(response);
      alert(`Usuário com o ID ${id} excluído com sucesso!`);
      navigate(`/users`);
    }

    function handleCancel(e) {
      e.preventDefault();
      navigate(`/users`);
    }

    return (
      <>
        <h1 className='page-title'>Excluir Usuário</h1>
        <div id="deleted-post">
          <p className="name-post">Nome: {name}</p>
          <p className="description-post">Nome de Usuário: {username}</p>
          <p className="author">Email: {email}</p>
          <p className="id">ID: {id}</p>
          <p className="id">Photo: {photo}</p>
          <p className="id">Permissão: {usertype}</p>
          <div className="button-container">
            <button type="submit" onClick={handleSubmit}>Confirmar</button>
            <button type="submit" onClick={handleCancel}>Cancelar</button>
          </div>
        </div>
      </>
    );
  }

export default DeleteUser;
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PostCard from '../../components/PostCard/PostCard';
import './CreatePosts.css';
import * as dotenv from 'dotenv';

dotenv.config();

function CreatePosts() {
  const [posts, setPosts] = useState([]);

  const API = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
  });

  useEffect(() => {
    const getPosts = async () => {
      const { data: posts } = await API.get("/posts");
      const sortedPosts = posts.sort((a, b) => new Date(b.date) - new Date(a.date));
      setPosts(sortedPosts);
      console.log(posts);     
    }
    getPosts();
  }, []);

  return (
    <>
      <div className="post-content">
        {
          posts.map((post, index) => (<PostCard key={index} post={post} />))
        }
      </div>
      {console.log(posts)}
      <div>
      </div>
    </>
  );
}

export default CreatePosts;
// AuthContext.js
import React, { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { API, createSession, getUsers } from "../Services/api";

export const AuthContext = createContext();

const baseUrl = process.env.REACT_APP_BASE_URL;

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const recoverUser = () => {
      const recoveredUser = localStorage.getItem("user");
      if (recoveredUser) {
        setUser(JSON.parse(recoveredUser));
      }
      setLoading(false);
    };

    recoverUser();
  }, []);

  const login = async (username, password) => {
    try {
      const response = await createSession(username, password);
      const newUser = await getUsers(response.data.users);
      const loggedUser = newUser.find((user) => user.username === username);

      const token = response.data.access_token;

      localStorage.setItem('user', JSON.stringify(loggedUser));
      localStorage.setItem('token', token);

      API.defaults.headers.Authorization = `Bearer ${token}`;
      setUser(loggedUser);
      navigate('/');
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  const SignUp = async (name, email, username, usertype, photo, tenantId, password, confirmPassword) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ name, email, username, usertype, photo, tenantId, password, confirmPassword })
    };

    try {
      const url = `${baseUrl}/users`;
      const response = await fetch(url, requestOptions);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      navigate('/login');
    } catch (error) {
      console.error('There was an error!', error);
    }
  };

  const logout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    API.defaults.headers.Authorization = null;

    setUser(null);
    navigate('/login', { replace: true }); // Usa replace para evitar que o usuário retorne à página anterior
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <AuthContext.Provider value={{ authenticated: !!user, user, login, logout, SignUp }}>
      {children}
    </AuthContext.Provider>
  );
};

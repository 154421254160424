import React, { useContext, useEffect, useState } from "react";
import { ThemeProvider } from "styled-components";
import Toggle from "./components/Toggler";
import { useDarkMode } from "./components/useDarkMode";
import { GlobalStyles } from "./components/GlobalStyles";
import { lightTheme, darkTheme } from "./components/Themes";
import NavbarTop from "./components/navbartop/NavbarTop";
import styled from "styled-components";
import AppRoutes from "./AppRoutes";
import Footer from "./components/footer/footer";
import axios from "axios";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Importar estilos do carrossel
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import dotenv from 'dotenv';
import { AuthContext } from './Contexts/auth';

dotenv.config();

const WelcomeMessage = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100px;
  padding-left: 20px;
  padding-right: inherit;
`;

const ToggleButtonWrapper = styled.div`
  display: flex;
  background-color: #01bdde;
  justify-content: flex-end;
  align-items: center;
  height: 50px;
  padding-right: 20px;
  padding-top: auto;
`;

const FooterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 100%;
  padding-top: 7vh;
`;

const AccessCount = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  padding-right: 20px;
`;

const ChangeBannerButton = styled.button`
  margin-left: 20px;
  padding: 10px 20px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const Modal = styled.div`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 800px;
  max-height: 80%;
  overflow-y: auto;
  padding-bottom: 40px;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const ModalTitle = styled.h2`
  margin: 0;
`;

const CloseButton = styled.button`
  background-color: #DE012D;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 5px 10px;

  &:hover {
    background-color: #a0001c;
  }
`;

const BannerList = styled.ul`
  list-style: none;
  padding: 0;
`;

const BannerItem = styled.li`
  margin-bottom: 10px;
  cursor: pointer;
`;

const BannerImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 5px;
`;

function App() {
  const { user } = useContext(AuthContext); // Certifique-se de que o AuthContext está importado corretamente
  const [theme, themeToggler, mountedComponent] = useDarkMode();
  const [accessCounts, setAccessCounts] = useState(0);
  const [selectedBanners, setSelectedBanners] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [banners, setBanners] = useState([]);
  const [loading, setLoading] = useState(true); // Adiciona estado de carregamento
  const themeMode = theme === "light" ? lightTheme : darkTheme;

  const baseUrl = process.env.REACT_APP_BASE_URL;

  // Busca contagens de acesso quando o usuário muda
  useEffect(() => {
    const fetchAccessCounts = async () => {
      if (user?.name) {
        try {
          const response = await axios.get(`${baseUrl}/user-access`, {
            headers: {
              'Cache-Control': 'no-cache',
              'Pragma': 'no-cache',
              'If-Modified-Since': '0'
            }
          });
          setAccessCounts(response.data);
        } catch (error) {
          console.error('Erro ao buscar os acessos:', error);
          alert('Houve um erro ao buscar os acessos. Por favor, tente novamente mais tarde.');
        }
      }
    };

    fetchAccessCounts();
  }, [user, baseUrl]);

  // Busca banners quando o componente é montado
  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const response = await axios.get(`${baseUrl}/uploads/images/banner`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'If-Modified-Since': '0'
          }
        });
        if (response.data && Array.isArray(response.data)) {
          setBanners(response.data);
          // Quantidade de banners a serem exibidos na galeria de banners proncipal
          setSelectedBanners(response.data.slice(0, 9).map(banner => banner.url));
        }
      } catch (error) {
        console.error('Erro ao buscar os banners:', error);
      } finally {
        setLoading(false); // Define o carregamento como falso quando a solicitação for concluída
      }
    };

    fetchBanners();
  }, [baseUrl]);

  if (!mountedComponent) return <div />;

  const toggleBannerSelection = (url) => {
    setSelectedBanners((prevSelectedBanners) => {
      if (prevSelectedBanners.includes(url)) {
        return prevSelectedBanners.filter((banner) => banner !== url);
      } else {
        return [...prevSelectedBanners, url];
      }
    });
  };

  return (
    <ThemeProvider theme={themeMode}>
      <GlobalStyles />
      <div className="App" id="App">
        <NavbarTop />
        <div className="banner-navpar-parent">
          {loading ? (
            <Skeleton height={300} width={`100%`} style={{ marginBottom: '1rem', borderRadius: '8px' }} />
          ) : (
            <Carousel autoPlay infiniteLoop showThumbs={false} showStatus={false}>
              {Array.isArray(selectedBanners) && selectedBanners.length > 0 ? (
                selectedBanners.map((banner, index) => (
                  <div key={index}>
                    <img className="banner_home" alt={`banner-home-${index}`} src={banner} />
                  </div>
                ))
              ) : (
                <div>Nenhum banner disponível</div>
              )}
            </Carousel>
          )}
        </div>
        <ToggleButtonWrapper>
          {loading ? (
            <Skeleton height={50} width={`100%`} style={{ marginBottom: '1rem', borderRadius: '8px' }} />
          ) : (
            <>
              <WelcomeMessage>{ user? 'Bem-vindo ' + user.name : 'Você não está autenticado!'}</WelcomeMessage>
              <Toggle isOn={theme === "dark"} toggleTheme={themeToggler} className="toogleTheme" />
              {user?.usertype === "administrator" && (
                <ChangeBannerButton onClick={() => setShowModal(true)}>Escolher Banners</ChangeBannerButton>
              )}
            </>
          )}
        </ToggleButtonWrapper>
        <AppRoutes />
      </div>
      <FooterWrapper>
        {loading ? (
          <Skeleton height={50} width={`100%`} style={{ marginTop: '7vh', borderRadius: '8px' }} />
        ) : (
          <Footer />
        )}
      </FooterWrapper>
      <Modal show={showModal}>
        <ModalContent>
          <ModalHeader>
            <ModalTitle>Escolher Banners</ModalTitle>
            <CloseButton onClick={() => setShowModal(false)}>X</CloseButton>
          </ModalHeader>
          <BannerList>
            {banners.map((banner, index) => (
              <BannerItem key={index} onClick={() => toggleBannerSelection(banner.url)}>
                <BannerImage
                  src={banner.url}
                  alt={`banner-${index}`}
                  className={selectedBanners.includes(banner.url) ? 'selected' : ''}
                />
              </BannerItem>
            ))}
          </BannerList>
        </ModalContent>
      </Modal>
    </ThemeProvider>
  );
}

export default App;

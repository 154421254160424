import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import ReactSelect from 'react-select';
import { AuthContext } from '../../Contexts/auth';
import { Link, useNavigate } from 'react-router-dom';

const SignUp = () => {
  const navigate = useNavigate();
  const { SignUp } = useContext(AuthContext);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    username: '',
    email: '',
    usertype: '',
    photo: '', // Torne opcional se não for sempre preenchido
    password: '',
    confirmPassword: '',
    tenantId: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSelect = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
    const usertype = selectedOptions.map(option => option.value).join(', ');
    setFormData((prevData) => ({ ...prevData, usertype }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await SignUp(
        formData.name,
        formData.email,
        formData.username,
        formData.usertype,
        formData.photo,
        formData.tenantId,
        formData.password,
        formData.confirmPassword
      );
      alert('Cadastro realizado com sucesso!');
      navigate('/login');
    } catch (error) {
      console.log(error);
    }
  };

  const options = [
    { value: 'aluno', label: 'Aluno' },
    { value: 'contribuinte', label: 'Contribuinte' },
    { value: 'editor', label: 'Editor' },
    { value: 'administrator', label: 'Administrador' },
  ];

  return (
    <Container>
      <FormWrapper>
        <FormTitle>Cadastre-se</FormTitle>
        <Form onSubmit={handleSubmit}>
          <Input
            type="text"
            name="name"
            placeholder="Nome completo"
            value={formData.name}
            onChange={handleChange}
            required
          />
          <Input
            type="text"
            name="username"
            placeholder="Nome de Usuário"
            value={formData.username}
            onChange={handleChange}
            required
          />
          {/* <Input
            type="email"
            name="email"
            placeholder="Endereço de e-mail"
            value={formData.email}
            onChange={handleChange}
            required
          /> */}
          {/* <Input
            type="text"
            name="photo"
            placeholder="Link para sua foto (opcional)"
            value={formData.photo}
            onChange={handleChange}
          /> */}
          {/* <Select
            className='select__control'
            name='usertype'
            value={selectedOptions}
            onChange={handleSelect}
            options={options}
            isMulti
            placeholder="Selecione as opções"
            required
          /> */}
          {/* <Input
            type="password"
            name="password"
            placeholder="Senha"
            value={formData.password}
            onChange={handleChange}
            required
          /> */}
          {/* <Input
            type="password"
            name="confirmPassword"
            placeholder="Confirme a senha"
            value={formData.confirmPassword}
            onChange={handleChange}
            required
          /> */}
          <Input
            type="text"
            name="tenantId"
            placeholder="ID do Locatário"
            value={formData.tenantId}
            onChange={handleChange}
            required
          />
          <Button type="submit">Cadastrar</Button>
        </Form>
        <SignInLink>
          Já tem uma conta? <Link to="/login">Faça login</Link>
        </SignInLink>
      </FormWrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  padding-top: 5vh;
  padding-bottom: 5vh;
  background-color: #f5f5f5;
  justify-content: center;
  align-items: center;
  max-height: 700vh;
`;

const FormWrapper = styled.div`
  width: 400px;
  background-color: #fff;
  padding: 40px;
  border-radius: 5px;
`;

const FormTitle = styled.h1`
  font-size: 2.5rem;
  font-weight: bold;
  color: #212529;
  margin-bottom: 40px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Input = styled.input`
  width: 100%;
  height: 50px;
  padding: 0 20px;
  margin-bottom: 20px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  font-size: 1.2rem;
  color: #212529;
  transition: border-color 0.2s ease-in-out;

  &:focus {
    border-color: #f44336;
  }
`;

const Button = styled.button`
  width: 100%;
  height: 50px;
  margin-bottom: 20px;
  border: none;
  border-radius: 5px;
  background-color: #f44336;
  font-size: 1.2rem;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #d32f2f;
  }
`;

const SignInLink = styled.p`
  font-size: 1.2rem;
  font-weight: bold;
  color: #f44336;
  text-decoration: none;
  transition: color 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    color: #d32f2f;
  }
`;

const Select = styled(ReactSelect)`
  .select__control {
    width: 100%;
    height: 50px;
    padding: 0 20px;
    margin-bottom: 20px;
    border: 1px solid #ced4da;
    border-radius: 5px;
    font-size: 1.2rem;
    color: #212529;
    transition: border-color 0.2s ease-in-out;

    &:hover {
      border-color: #ced4da;
    }

    &:focus {
      border-color: #f44336;
      box-shadow: none;
    }
  }

  .select__menu {
    border: 1px solid #ced4da;
    border-radius: 5px;
    margin-top: -1px;
  }

  .select__option {
    color: #212529;
  }

  .select__option--is-selected {
    background-color: #f44336;
    color: #fff;
  }

  .select__option--is-focused {
    background-color: #f5f5f5;
  }
`;

export default SignUp;

import React, { useEffect, useState } from 'react';
import { getMessages } from '../../Services/api';
import styled from 'styled-components';
// import { MdDoneAll, MdDone } from 'react-icons/md';
// import Tooltip from '@mui/material/Tooltip';
import axios from 'axios';
import * as dotenv from 'dotenv';

dotenv.config();

const TimelineContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem ;
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f7f7f7;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
  width: 90%;
  position: relative;
`;

const MessageCloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  color: #666;
  font-size: 16px;
  font-weight: bold;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const MessageTitle = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
`;

const MessageBody = styled.p`
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
`;

const MessageCreator = styled.p`
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
`;

const DateStyle = styled.p`
  font-size: 12px;
  color: #666;
  margin-bottom: 20px;
`;

function Messages() {
  const [messages, setMessages] = useState([]);
  const [messageReadIds, setMessageReadIds] = useState([]);
  const [deletedMessages, setDeletedMessages] = useState([]);
  // const [dataFormatada, setDataFormatada] = useState([]);

  const baseURL = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    getMessages().then((response) => {
      setMessages(response.filter((message) => !deletedMessages.includes(message._id)));
    });
  }, [deletedMessages]);

  useEffect(() => {
    // obter todas as mensagens
    getMessages().then((response) => {
      setMessages(response.filter((message) => !deletedMessages.includes(message._id)));
    });

    // atualizar o estado 'sent' para todas as mensagens
    messages.forEach((message) => {
      axios.put(`${baseURL}${message._id}`, {
        sent: true
      }, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      }).then((response) => {
        console.log(`Message ${message._id} sent`);
      }).catch((error) => {
        console.error(`Error sending message ${message._id}: ${error}`);
      });
    });
  }, []);

  const handleCloseMessage = (messageId) => {
    const deletedMessages = JSON.parse(localStorage.getItem('deletedMessages')) || [];
    setDeletedMessages([...deletedMessages, messageId]);
    
    // Adicionando a mensagem excluída ao localStorage
    localStorage.setItem('deletedMessages', JSON.stringify([...deletedMessages, messageId]));

    // Removendo a mensagem excluída do localStorage
    const readMessages = JSON.parse(localStorage.getItem('readMessages')) || [];
    localStorage.setItem('readMessages', JSON.stringify(readMessages.filter((id) => id !== messageId)));

    setMessages(messages.filter((message) => message._id !== messageId));
  };


  useEffect(() => {
    // obter a lista de mensagens lidas no localStorage
    const readMessages = JSON.parse(localStorage.getItem('readMessages')) || [];
    // criar um objeto com o ID de cada mensagem lida para facilitar a verificação posterior
    const readMessagesMap = readMessages.reduce((map, id) => ({ ...map, [id]: true }), {});
    // atualizar o estado lido para cada mensagem na lista de mensagens
    setMessageReadIds(messages.map(message => readMessagesMap[message._id] || message.read));
  }, [messages]);

  useEffect(() => {
    // obter a lista de mensagens excluídas no localStorage
    const deletedMessages = JSON.parse(localStorage.getItem('deletedMessages')) || [];
    // atualizar o estado de mensagens excluídas
    setDeletedMessages(deletedMessages);
  }, []);

  return (
    <TimelineContainer>
      {messages.map(message => {
        const { createdAt } = message;

        const convertData = () => {
          if (!createdAt) {
            return '';
          }
          const dataCriacaoPost = createdAt;
          console.log(dataCriacaoPost);
          const newDate = new Date(dataCriacaoPost);
          const dia = newDate.getDate().toString().padStart(2, '0');
          const mes = (newDate.getMonth() + 1).toString().padStart(2, '0');
          const ano = newDate.getFullYear();
          const hora = newDate.getHours().toString().padStart(2, '0');
          const minutos = newDate.getMinutes().toString().padStart(2, '0');
          const segundos = newDate.getSeconds().toString().padStart(2, '0');

          const dataFormatada = `${dia}/${mes}/${ano} ${hora}:${minutos}:${segundos}`;
          return dataFormatada;
        }

        const dataFormatada = convertData();

        return (
          <MessageContainer key={message._id} style={{ display: deletedMessages.includes(message._id) ? 'none' : 'flex' }}>
            <MessageCloseButton onClick={() => handleCloseMessage(message._id)}>
              X
            </MessageCloseButton>
            <MessageTitle>{message.subject}</MessageTitle>
            <MessageBody>{message.content}</MessageBody>
            <MessageCreator>{message.creator}</MessageCreator>
            <DateStyle>{dataFormatada}</DateStyle>
          </MessageContainer>
        );
      })}
    </TimelineContainer>
  );
}

export default Messages;

import React from "react";
import styled from 'styled-components';
import { useNavigate } from "react-router-dom";

const Button = styled.button`
background-color: #DC172E;
color: white;
border: none;
padding: 10px 20px;
border-radius: 4px;
cursor: pointer;
font-size: 16px;

&:hover {
background-color: #fff;
color: #DC172E;
}

&:focus {
outline: none;
box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
`;

function Card({ turma }) {

  const [clicado, setClicado] = React.useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/posts/classe/${turma.name}`)
    console.log(turma)
    setClicado(!clicado);
  };


 return (
    <div className="card">
        <div>
          <h1 className="name-turma">{turma.name}</h1>
          <p className="description-turma">{turma.description}</p>
          {clicado ? (
            <p className="link-turma">{`posts/classe/${turma.link}`}</p>
          ) : (
            <Button className="button-turma" onClick={handleClick}>
              Acessar
            </Button>
          )}
        </div> 
    </div>
  )

}
export default Card;
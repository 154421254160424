import React, { useEffect, useState } from "react";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import "./PostCard.css";


function PostCard({ post }){
  const [dataFormatada, setDataFormatada] = useState('');

  const navigate = useNavigate();

  function handleEdit({id}) {
    console.log("edit");
    navigate(`/posts/${id}/editposts`);
  }

  function handleDelete({id}) {
    console.log("delete");
    navigate(`/posts/${id}/deleteposts`);
  }

  useEffect(() => {
    const { createdAt } = post;
    const convertData = () => {
      if (!createdAt) {
        return '';
      }
      const dataCriacaoPost = createdAt;
      console.log(dataCriacaoPost);
      const newDate = new Date(dataCriacaoPost);
      const dia = newDate.getDate().toString().padStart(2, '0');
      const mes = (newDate.getMonth() + 1).toString().padStart(2, '0');
      const ano = newDate.getFullYear();
      const hora = newDate.getHours().toString().padStart(2, '0');
      const minutos = newDate.getMinutes().toString().padStart(2, '0');
      const segundos = newDate.getSeconds().toString().padStart(2, '0');

      const dataFormatada = `${dia}/${mes}/${ano} ${hora}:${minutos}:${segundos}`;
      return dataFormatada;
    }
    const data = convertData();
    setDataFormatada(data);

  }, []);


  return (
    <div className="post-content">
      <div className="card">
      <h1 className="title">Card</h1>
        <h1 className="name-post">{post.title}</h1>
        <p className="description-post">Descrição: {post.content}</p>
        <p className="author">Autor: {post.creator}</p>
        <p className="date">Data: {dataFormatada}</p>
        <p className="id">ID: {post._id}</p>
        <div className="buttons">
          <IconButton aria-label="delete" onClick={() => handleEdit({ id: post._id })}>
            <EditIcon />
          </IconButton>
          <IconButton aria-label="delete" onClick={() => handleDelete({ id: post._id })}>
            <DeleteIcon />
          </IconButton>
        </div>
      </div> 
    </div>
  );
}

export default PostCard;
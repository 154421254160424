import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './newpost.css';
import Select from 'react-select';
import { AuthContext } from '../../Contexts/auth';
import * as dotenv from 'dotenv';

dotenv.config();

function NewPost() {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [classId, setClassId] = useState([]);
  const [creator, setCreator] = useState('');
  const navigate = useNavigate();

  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (user) {
      setCreator(user.name);
    }
  }, [user]);

  const API = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
  });

  async function handleSubmit(e) {
    e.preventDefault();
    const data = {
      title,
      content,
      creator: user.name,
      classId: classId.map(option => option.value)
    };
    // create a post with authentication
    const token = localStorage.getItem('token');
    const response = await API.post('/posts', data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
    console.log(response);
    console.log(classId);
    alert('Post criado com sucesso!');
    navigate('/admin');
  }

  return (
    <>
      <h1 className='page-title'>Novo Post</h1>
      <div id="new-post">
        <form onSubmit={handleSubmit} className="form">
          <div className="input-block">
            <input
              name="title"
              id="title"
              placeholder='Título do post'
              required
              type="text"
              value={title}
              onChange={e => setTitle(e.target.value)}
            />
          </div>
          <Select
            name="classe"
            id="turma"
            placeholder='Turma'
            isMulti={true}
            required
            value={classId}
            onChange={selectedOptions => setClassId(selectedOptions)}
            options={[
              { value: 'Infantil-I', label: 'Infantil-I' },
              { value: 'Infantil-II', label: 'Infantil-II' },
              { value: 'Infantil-III', label: 'Infantil-III' },
              { value: 'Infantil-IV', label: 'Infantil-IV' },
              { value: 'Infantil-V', label: 'Infantil-V' },
              { value: 'primeiro-ano', label: '1º Ano' },
              { value: 'segundo-ano', label: '2º Ano' },
              { value: 'terceiro-ano', label: '3º Ano' },
              { value: 'quarto-ano', label: '4º Ano' },
              { value: 'quinto-ano', label: '5º Ano' },
            ]}
          />
          <div className="input-block">
            <textarea
              name="content"
              id="content"
              required
              type="text"
              placeholder='Conteúdo do post'
              value={content}
              onChange={e => setContent(e.target.value)}
            />
          </div>
          <button type="submit">Salvar</button>
        </form>
      </div>
    </>
  )
}

export default NewPost;

import React from 'react';
import { Container, Typography, Accordion, AccordionSummary, AccordionDetails, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQ = () => {
  const faqList = [
    {
      question: "Quais as documentações necessárias para efetuar a matrícula?",
      answer: "Você precisará dos seguintes documentos: Certidão de nascimento, RG dos responsáveis, Comprovante de residência, Histórico escolar."
    },
    {
      question: "Como faço para agendar um teste de admissão?",
      answer: "Para agendar um teste de admissão, entre em contato com nossa secretaria pelo telefone ou através do formulário de contato em nosso site."
    },
    {
      question: "Como agendar uma visita presencial na colégio?",
      answer: "Visitas presenciais podem ser agendadas diretamente pelo nosso site ou ligando para a secretaria. Escolha um horário conveniente e venha nos conhecer!"
    },
    {
      question: "Como transferir o aluno de escola ou de outro Estado?",
      answer: "Para transferências, será necessário o histórico escolar e os documentos pessoais do aluno. Nossa equipe dará suporte para a transição."
    },
    {
      question: "Onde posso me informar sobre os valores para matrícula?",
      answer: "Os valores para matrícula podem ser consultados diretamente na secretaria do colégio ou solicitados através de nosso site no formulário de contato."
    },
  ];

  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: '#f5f5f5', // Background da seção
        py: 6, // Espaçamento vertical
        display: 'flex',
        justifyContent: 'center', // Centralizar o conteúdo horizontalmente
      }}
    >
      <Container maxWidth="md" sx={{ width: '80%' }}>
        <Typography
          variant="h4"
          component="h2"
          gutterBottom
          sx={{ textAlign: 'center', fontWeight: 'bold', color: '#0a0a3b' }}
        >
          Perguntas Frequentes
          <Box component="span" sx={{ display: 'block', borderBottom: '3px solid #c90000', width: '100px', margin: '8px auto 0' }} />
        </Typography>
        {faqList.map((faq, index) => (
          <Accordion key={index} sx={{ my: 2 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
              sx={{
                backgroundColor: '#01bdde',
                color: '#ffffff',
                fontWeight: 'bold',
                borderRadius: '8px',
                '&:hover': {
                  backgroundColor: '#009bbd',
                },
              }}
            >
              {faq.question}
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: '#f0f0f0' }}>
              <Typography variant="body1">{faq.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Container>
    </Box>
  );
};

export default FAQ;

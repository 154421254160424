import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { AuthContext } from '../../Contexts/auth';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PostAddIcon from '@mui/icons-material/PostAdd';
import Tooltip from '@mui/material/Tooltip';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import ArticleIcon from '@mui/icons-material/Article';

const AdminPanelWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(to bottom right, #5b4288, #DE012D);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  padding-top: 5vh;
  padding-bottom: 50vh;
`;

const AdminPanelHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  margin-bottom: 40px;
`;

const AdminPanelTitle = styled.h1`
  font-size: 2.5rem;
  font-weight: bold;
  color: #212529;
`;

const AdminPanelContent = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  @media (max-width: 320px) {
    flex-direction: column;
  }

  @media (min-width: 321px) and (max-width: 480px) {
    flex-direction: column;
  }

  @media (min-width: 481px) and (max-width: 768px) {
    flex-direction: column;
  }
`;

const AdminPanelContentItem = styled.div`
  width: 300px;
  height: 200px;
  margin: 20px;
  background-color: #f44336;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  transition: transform 0.2s ease-in-out;

  @media (max-width: 320px) {
    width: 250px;
    height: 150px;
  }

  @media (min-width: 321px) and (max-width: 480px) {
    width: 250px;
    height: 150px;
  }

  @media (min-width: 481px) and (max-width: 768px) {
    width: 250px;
    height: 150px;
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    width: 250px;
    height: 150px;
  }

  @media (min-width: 1025px) and (max-width: 1280px) {
    width: 250px;
    height: 150px;
  }

  @media (min-width: 1281px) and (max-width: 1440px) {
    width: 250px;
    height: 150px;
  }

  @media (min-width: 1441px) and (max-width: 1600px) {
    width: 250px;
    height: 150px;
  }

  @media (min-width: 1601px) and (max-width: 1920px) {
    width: 250px;
    height: 150px;
  }

  @media (min-width: 1921px) and (max-width: 2560px) {
    width: 250px;
    height: 150px;
  }

  &:hover {
    transform: scale(1.05);
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.6);
  }
`;

const AdminPanelContentLink = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-decoration: none;
  cursor: pointer;
`;

const AdminPanelContentTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  color: #212529;
  margin-top: 10px;
`;

const AdminPanelBlockedAccess = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #F8F9FA;
  color: #212529;
  padding-top: 40px;
`;

const SubMenu = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  position: absolute;
  top: 60px;
  right: 10px;
  padding: 10px;
  text-decoration: none;

  & a {
    text-decoration: none;
    color: #212529;
    margin-bottom: 10px;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const AdminPanel = () => {
  const { user } = useContext(AuthContext);
  const [showUserSubMenu, setShowUserSubMenu] = useState(false);
  const [showPostSubMenu, setShowPostSubMenu] = useState(false);

  const toggleUserSubMenu = (event) => {
    event.stopPropagation(); // Prevenir o clique no Link
    setShowUserSubMenu(!showUserSubMenu);
  };

  const togglePostSubMenu = (event) => {
    event.stopPropagation(); // Prevenir o clique no Link
    setShowPostSubMenu(!showPostSubMenu);
  };

  if (user.usertype === 'administrator') {
    return (
      <AdminPanelWrapper>
        <AdminPanelHeader>
          <AdminPanelTitle>
            <SettingsApplicationsIcon sx={{ fontSize: 40 }} />Admin Panel
          </AdminPanelTitle>
        </AdminPanelHeader>
        <AdminPanelContent>
          {/* <AdminPanelContentItem>
            <Tooltip title="Crie novos posts">
              <AdminPanelContentLink onClick={togglePostSubMenu}>
                <AdminPanelContentTitle>
                  <ArticleIcon sx={{ fontSize: 150 }} />
                </AdminPanelContentTitle>
              </AdminPanelContentLink>
            </Tooltip>
            {showPostSubMenu && (
              <SubMenu>
                <Link to="/posts/newpost">
                  <AdminPanelContentTitle>
                    <PostAddIcon sx={{ fontSize: 20 }} /> Criar Post
                  </AdminPanelContentTitle>
                </Link>
                <Link to="/posts">
                  <AdminPanelContentTitle>
                    <ArticleIcon sx={{ fontSize: 20 }} /> Ver Posts
                  </AdminPanelContentTitle>
                </Link>
              </SubMenu>
            )}
          </AdminPanelContentItem> */}
          {/* <AdminPanelContentItem>
            <Tooltip title="Gerencie suas turmas">
              <Link to="/turmas" style={{ textDecoration: 'none' }}>
                <AdminPanelContentLink>
                  <AdminPanelContentTitle>
                    <SchoolIcon sx={{ fontSize: 150 }} />
                  </AdminPanelContentTitle>
                </AdminPanelContentLink>
              </Link>
            </Tooltip>
          </AdminPanelContentItem> */}
          <AdminPanelContentItem>
            <Tooltip title="Gerencie seus usuários">
              <AdminPanelContentLink onClick={toggleUserSubMenu}>
                <AdminPanelContentTitle>
                  <ManageAccountsIcon sx={{ fontSize: 150 }} />
                </AdminPanelContentTitle>
              </AdminPanelContentLink>
            </Tooltip>
            {showUserSubMenu && (
              <SubMenu>
                <Link to="/users/newuser">
                  <AdminPanelContentTitle>
                    <PostAddIcon sx={{ fontSize: 20 }} /> Adicionar Usuário
                  </AdminPanelContentTitle>
                </Link>
                <Link to="/users">
                  <AdminPanelContentTitle>
                    <ManageAccountsIcon sx={{ fontSize: 20 }} /> Ver Usuários
                  </AdminPanelContentTitle>
                </Link>
              </SubMenu>
            )}
          </AdminPanelContentItem>
          {/* <AdminPanelContentItem>
            <Tooltip title="Envie uma mensagem para os usuários">
              <Link to="/timeline/sent" style={{ textDecoration: 'none' }}>
                <AdminPanelContentLink>
                  <AdminPanelContentTitle>
                    <MessageIcon sx={{ fontSize: 150 }} />
                  </AdminPanelContentTitle>
                </AdminPanelContentLink>
              </Link>
            </Tooltip>
          </AdminPanelContentItem> */}
          {/* <AdminPanelContentItem>
            <Tooltip title="Analise suas métricas">
              <Link to="/metrics" style={{ textDecoration: 'none' }}>
                <AdminPanelContentLink>
                  <AdminPanelContentTitle>
                    <StackedLineChartIcon sx={{ fontSize: 150 }} />
                  </AdminPanelContentTitle>
                </AdminPanelContentLink>
              </Link>
            </Tooltip>
          </AdminPanelContentItem> */}
          <AdminPanelContentItem>
            <Tooltip title="Gerencie suas atividades">
              <Link to="/atividades" style={{ textDecoration: 'none' }}>
                <AdminPanelContentLink>
                  <AdminPanelContentTitle>
                    <ArticleIcon sx={{ fontSize: 150 }} />
                  </AdminPanelContentTitle>
                </AdminPanelContentLink>
              </Link>
            </Tooltip>
          </AdminPanelContentItem>
          {/* <AdminPanelContentItem>
            <Tooltip title="Gerencie suas avaliações">
              <Link to="/avaliacoes" style={{ textDecoration: 'none' }}>
                <AdminPanelContentLink>
                  <AdminPanelContentTitle>
                    <PsychologyIcon sx={{ fontSize: 150 }} />
                  </AdminPanelContentTitle>
                </AdminPanelContentLink>
              </Link>
            </Tooltip>
          </AdminPanelContentItem> */}
          <AdminPanelContentItem>
            <Tooltip title="Gerencie suas mídias">
              <Link to="/mideas" style={{ textDecoration: 'none' }}>
                <AdminPanelContentLink>
                  <AdminPanelContentTitle>
                    <PermMediaIcon sx={{ fontSize: 150 }} />
                  </AdminPanelContentTitle>
                </AdminPanelContentLink>
              </Link>
            </Tooltip>
          </AdminPanelContentItem>
        </AdminPanelContent>
      </AdminPanelWrapper>
    );
  } else {
    return (
      <AdminPanelBlockedAccess>
        <h1>Acesso Bloqueado</h1>
        <p>Você não tem permissão para acessar este painel administrativo.</p>
      </AdminPanelBlockedAccess>
    );
  }
};

export default AdminPanel;

// Página de Edição de Post

import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '../../Contexts/auth';
import axios from 'axios';
import * as dotenv from 'dotenv';

dotenv.config();
// import Select from 'react-select';

function EditPost() {
  const { user } = useContext(AuthContext);
  const { id } = useParams();
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [classe, setClasse] = useState('');
  const [author, setAuthor] = useState('');
  const navigate = useNavigate();

  const API = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
  });

  useEffect(() => {
    async function fetchPost() {
      const response = await API.get(`/posts/${id}`);
      const { title, content, author, classe } = response.data;
      console.log(response)
      setTitle(title);
      setContent(content);
      setAuthor(author);
      setClasse(classe);
    }
    fetchPost();
  }, [id]);

  async function handleSubmit(e) {
    e.preventDefault();
    const data = {
      title,
      content,
      author,
      classe
    };
    // editar um post com autenticação
    const token = localStorage.getItem('token');
    const response = await API.patch(`/posts/${id}`, data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
    console.log(response);
    alert('Post editado com sucesso!');
    navigate(`/posts/${id}`);
  }

  function handleCancel(e) {
    e.preventDefault();
    navigate(`/posts`);
  }

  if(user.usertype === 'administrator' || !user.usertype) {
  return (
    <>
      <h1 className='page-title'>Editar Post</h1>
      <div id="new-post">
        <form onSubmit={handleSubmit} className="form">
          <div className="input-block">
            <input
              name="title"
              id="title"
              placeholder='Título do post'
              required
              type="text"
              value={title}
              onChange={e => setTitle(e.target.value)}
            />
          </div>
          <div className="input-block">
            <input
              name="author"
              id="author"
              placeholder='Autor'
              required
              type="text"
              value={author}
              onChange={e => setAuthor(e.target.value)}
            />
          </div>
          <div className="input-block">
            <input
              name="turma"
              className="turma"
              placeholder='Turma'
              id="turma"
              required
              value={classe}
              onChange={e => setClasse(e.target.value)}
            />
          </div>
          <div className="input-block">
            <textarea
              name="content"
              id="content"
              required
              type="text"
              placeholder='Conteúdo do post'
              value={content}
              onChange={e => setContent(e.target.value)}
            />
          </div>
          <button type="submit">Salvar</button>
          <button type="submit" onClick={handleCancel}>Cancelar</button>
        </form>
      </div>
    </>
  );
  } else {
    return (
      <div>
        <h1>Você não tem permissão para acessar essa página.</h1>
      </div>
    )
  }
}

export default EditPost;

import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../../Contexts/auth';
import './Alunos.css';
import * as dotenv from 'dotenv';

dotenv.config();

function Alunos() {
  const { user } = useContext(AuthContext);
  const [alunos, setAlunos] = useState([]);
  const navigate = useNavigate();

  const API = axios.create({
    baseUrl: process.env.REACT_APP_BASE_URL,
  });

  useEffect(() => {
    const getAlunos = async () => {
      try {
        const { data: todosAlunos } = await API.get('/users', {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        });
        const filterAlunos = todosAlunos.filter(aluno => aluno.usertype === 'aluno'); // Corrigido para aluno.usertype
        const ordenedAlunos = filterAlunos.sort((a, b) => new Date(b.date) - new Date(a.date));
        setAlunos(ordenedAlunos);
      } catch (error) {
        console.error("Erro ao buscar alunos:", error);
      }
    }
    getAlunos();
  }, []);

  async function handleDelete(id) {
    if (window.confirm('Você tem certeza que deseja excluir este aluno?')) {
      await API.delete(`/alunos/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      setAlunos(alunos.filter(aluno => aluno.id !== id));
    }
  }

  function handleEdit(id) {
    navigate(`/alunos/edit/${id}`);
  }

  if (user.usertype !== 'administrator') {
    return (
      <div>
        <h1>Você não tem permissão para acessar essa página.</h1>
      </div>
    );
  }

  return (
    <div>
      <h1 className='page-title'>Gerenciar Alunos</h1>
      <table className='alunos-table'>
        <thead>
          <tr>
            <th>Nome</th>
            <th>Email</th>
            <th>Turma</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {alunos.map(aluno => (
            <tr key={aluno.id}>
              <td>{aluno.name}</td>
              <td>{aluno.email}</td>
              <td>{aluno.classe}</td>
              <td>
                <button className='editBtn' onClick={() => handleEdit(aluno.id)}>Editar</button>
                <button onClick={() => handleDelete(aluno.id)}>Excluir</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Alunos;
